import React, { useState, useRef } from "react";

const OtpInput = ({ numInputs = 6, onOtpChange }) => {
  const [otp, setOtp] = useState(new Array(numInputs).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    if (!isNaN(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Auto focus to next input
      if (value !== "" && index < numInputs - 1) {
        inputRefs.current[index + 1].focus();
      }

      // Notify parent component of OTP change
      onOtpChange(newOtp.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      // Move focus to the previous input on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData("text/plain")
      .slice(0, numInputs)
      .split("");
    const newOtp = [...otp];

    pastedData.forEach((value, index) => {
      newOtp[index] = value;
    });

    setOtp(newOtp);

    // Notify parent component of OTP change
    onOtpChange(newOtp.join(""));
  };

  return (
    <div className="otp-input flex justify-center">
      {otp.map((value, index) => (
        <input
          key={index}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength="1"
          className="otp-box w-[40px] h-[40px] mx-2 border-4 text-center flex items-center justify-center"
          value={value}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          ref={(ref) => (inputRefs.current[index] = ref)}
        />
      ))}
    </div>
  );
};

export default OtpInput;
