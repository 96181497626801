import React from "react";

const ButtonLoader = ({
  type = "button",
  className = "",
  onClick,
  disabled = false,
  processing = false,
  children,
}) => {
  return (
    <button
      type={type}
      className={`relative px-6 w-1/2 py-3 text-white text-[16px] rounded-full bg-[--blue] poppin-500 ${className} ${
        processing ? "opacity-50 cursor-not-allowed" : ""
      }`}
      onClick={onClick}
      disabled={disabled || processing}
    >
      {processing && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-5 h-5 border-t-2 border-blue-200 border-solid rounded-full animate-spin" />
        </div>
      )}
      {children}
    </button>
  );
};

export default ButtonLoader;
