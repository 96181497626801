const initialState = {
  data: JSON.parse(localStorage.getItem("storeData")) || [],
};

const storeReducer = (state = initialState, { type, payload }) => {
  let newState;
  switch (type) {
    case "STORE_INFO":
      newState = {
        ...state,
        data: [payload.data],
      };
      break;
    default:
      newState = state;
  }

  // Store the new state in local storage
  // localStorage.setItem("storeData", JSON.stringify(newState.data));

  return newState;
};

export default storeReducer;
