import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const AvatarName = () => {
  const storedValue = useSelector((state) => state.store.data[0]);
  // Perform conditional checks before accessing properties
  const avatarImage = storedValue?.store?.venderProfileImage;
  const storeName = storedValue?.store?.storeCompanyName;
  const storeUrl = localStorage.getItem("storeName");

  return (
    <Link
      to={`/${storeUrl}`}
      className="flex justify-center items-center flex-row md:flex-col"
    >
      <div className="w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] md:w-[60px] md:h-[60px] mb-1">
        {avatarImage && (
          <img
            className="w-full h-full rounded-full"
            src={avatarImage}
            alt="User Avatar"
          />
        )}
      </div>

      {storeName && (
        <h1 className="ml-2 md:ml-0 poppin-600 text-[12px] sm:text-[18px] text-center md:text-[16px] lg:text-[20px]">
          {storeName}
        </h1>
      )}
    </Link>
  );
};

export default AvatarName;
