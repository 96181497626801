import React from "react";
import logo from "../../Assets/Images/logo.png";
import { useSelector } from "react-redux";
import nesmaspointlogo from "../../Assets/Images/logo.png";
const Footer = () => {
  const storeInfo = useSelector((state) => state.store);
  return (
    <div className="flex justify-center items-center flex-col w-full gap-10 py-10 px-4">
      <img
        className="w-[130px] sm:w-[180px]"
        src={logo}
        alt="NesMas Logo here"
      />
      <p className="text-[13px] sm:text-[16px] md:text-[18px] flex items-center gap-2">
        <span className="font-bold">
          {storeInfo?.data[0]?.store?.storeCompanyName}
        </span>
        Powered by
        <span>
          <img src={nesmaspointlogo} className="w-28" alt="nesmaspoint"/>
        </span>
      </p>
    </div>
  );
};

export default Footer;
