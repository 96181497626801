import React, { useEffect } from "react";

import AuthForm from "../../Components/Form/AuthForm";
import { CiLock, CiMail } from "react-icons/ci";
import InputPassword from "../../Components/Input/InputPassword";
import InputWrapper from "../../Components/Wrapper/InputWrapper";
import Input from "../../Components/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStoreInfo, login } from "../../Redux/actions";
import Loader from "../../Components/Loading/Loader";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import { loginSchema } from "../../Schema";
import ButtonLoading from "../../Components/Button/ButtonLoading";
import { errorToast } from "../../Utils/Toast";
let Once = true;

const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeInfo = useSelector((state) => state.store);
  const storeName = localStorage.getItem("storeName");
  const { loaderPrimary, loaderSecondary } = useSelector(
    (state) => state.loader
  );

  const { values, touched, errors, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: initialState,
      validationSchema: loginSchema,
      onSubmit: (values, action) => {
        dispatch(
          login({
            ...values,
            venderId: storeInfo.data[0].store.venderId.toString(),
          })
        )
          .then((res) => {
            action.resetForm();
            navigate(`/${storeName}`);
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              errorToast("Email or password incorrect");
            } else {
              errorToast("Internet connection error");
            }
          });
      },
    });

  // Handle loading state
  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(storeInfo.data)) {
        dispatch(getStoreInfo({ storeName }))
          .then(() => {
            console.log("Store found");
          })
          .catch((error) => {
            if (error?.response?.status === 400) {
              navigate("/notfound");
            } else {
              console.err(error);
            }
          });
      }
    }
  }, [dispatch, navigate, storeInfo, storeName]);
  return (
    <>
      {loaderPrimary ? (
        <Loader />
      ) : storeInfo.data.length ? (
        <AuthForm
          header="Login"
          description="Enter the required information to continue."
          storeInfo={storeInfo.data[0]}
        >
          <div className="w-full md:w-[80%] mx-auto pt-6 pb-2 px-2 md:px-4">
            <InputWrapper>
              <Input
                type="email"
                name="email"
                label="Email"
                placeholder="Enter your email address"
                icon={<CiMail />}
                onChange={handleChange}
                onBlur={handleBlur}
                touch={touched.email}
                error={errors.email}
                value={values.email}
              />
            </InputWrapper>

            <InputWrapper customclasses="mt-6">
              <InputPassword
                name="password"
                label="Password"
                placeholder="Enter your password"
                icon={<CiLock />}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                touch={touched.password}
                value={values.password}
              />
            </InputWrapper>
          </div>
          <div className="px-2 md:px-4 w-full md:w-[80%] mx-auto pt-2 pb-5 flex justify-between items-center">
            <div className="flex justify-start items-center">
              <input
                type="checkbox"
                id="remember"
                className="w-[15px] h-[15px]"
              />
              <label
                htmlFor="remember"
                className="poppin-500 text-[12px] sm:text-[14px] ml-2"
              >
                Remember me
              </label>
            </div>
            <Link
              to="/forget-password"
              className="poppin-500 text-[--red] text-[12px] sm:text-[14px]"
            >
              Forget Password ?
            </Link>
          </div>
          <div className="w-full text-center">
            {loaderSecondary ? (
              <ButtonLoading customClasses={"!w-1/2 !mx-auto !rounded-full"} />
            ) : (
              <button
                type="submit"
                className="px-6 w-1/2 py-3 text-white text-[16px] rounded-full bg-[--blue] poppin-500"
                onClick={handleSubmit}
              >
                Sign In
              </button>
            )}
          </div>
          <div className="w-full text-center py-3">
            <span className="poppin-500 text-[14px]">
              Don't have account ?
              <Link
                className="text-[--blue] poppin-500"
                to={`/signup/${storeName}`}
              >
                Sign Up
              </Link>
            </span>
          </div>
        </AuthForm>
      ) : (
        <section className="flex justify-center items-center w-screen h-screen">
          <h1 className="satoshi-700 text-[25px]">OOPS!</h1>
          <p>No store found</p>
        </section>
      )}
    </>
  );
};

export default Login;
