import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoNewspaperOutline } from "react-icons/io5";
import { GoInfo } from "react-icons/go";
import { SlLogin } from "react-icons/sl";
import { FaBagShopping } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

import { logout } from "../../Redux/actions";
const NavItems = () => {
  const navigate = useNavigate();
  // Use useSelector within the functional component
  const auth = useSelector((state) => state?.auth?.auth);
  const dispatch = useDispatch();
  const storeName = localStorage.getItem("storeName");
  // Define your base navlinks array without "Order History"
  const baseNavlinks = [
    {
      text: "Store",
      url: `/${storeName}`,
      icon: <FaBagShopping />,
    },
    {
      text: "About Us",
      url: `/about-us/${storeName}`,
      icon: <GoInfo />,
    },
  ];
  if (!auth) {
    baseNavlinks.push({
      text: "Login",
      url: "/login",
      icon: <SlLogin />,
    });
  }

  // Initialize navlinks with the base links
  let navlinks = baseNavlinks;

  // Check if auth data is present and add "Order History" link conditionally
  if (auth) {
    navlinks = [
      ...baseNavlinks,
      {
        text: "Order History",
        url: "/orders",
        icon: <IoNewspaperOutline />,
      },
    ];
  }

  if (auth) {
    navlinks.push({
      text: "Logout",
      icon: <SlLogin />,
    });
  }

  const handleLogout = () => {
    dispatch(logout()).then((response) => {
      navigate(`/${storeName}`);
    });
  };

  return (
    <ul className="flex flex-col md:flex-row w-full gap-4">
      {navlinks.map((navitem, index) => (
        <li key={index} className="w-full flex justify-center">
          {navitem.text === "Logout" ? (
            <button
              className="w-full text-center text-[16px] sm:text-[20px] poppin-500 py-2 my-2 whitespace-nowrap"
              onClick={handleLogout}
            >
              {navitem.text}
            </button>
          ) : (
            <Link
              to={navitem?.url}
              className="w-full text-center text-[16px] sm:text-[20px] poppin-500 py-2 my-2 whitespace-nowrap"
            >
              {navitem.text}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default NavItems;
