import React, { useState, useEffect, useContext } from "react";
import NavMenu from "../Components/Navbar/NavMenu";
import SectionHeader from "../Components/Header/SectionHeader";
import Footer from "../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../Components/Button/ButtonBack";
import { useParams } from "react-router-dom";
import { CartContext } from "../Context/cartContext";
import { addToCart, returnProductQuantity } from "../Utils/cartFUnctions";
import { errorToast } from "../Utils/Toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductDetail } from "../Redux/actions";
import Loader from "../Components/Loading/Loader";

const ProductDetail = () => {
  let { storeName } = useParams();
  localStorage.setItem("storeName" ,storeName)
  const [responseData, setResponseData] = useState(null);
  const { id } = useParams();
  const { cart, setCart } = useContext(CartContext);
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(
    parseInt(returnProductQuantity(id, cart))
  );
   storeName = localStorage.getItem("storeName");
  const navigate = useNavigate();
  const loading = useSelector((state) => state.loader.loaderSecondary);

  function handleAddToCart(e) {
    e.preventDefault();
    addToCart(cart, responseData, true, quantity)
      .then((result) => {
        setCart(result);
        localStorage.setItem("cart", JSON.stringify(result));
        setTimeout(() => {
          navigate("/checkout");
        }, 1000);
      })
      .catch((error) => {
        errorToast("Out of Stock");
        console.log("Add to cart product detail page error ===>", error);
      });
  }

  function addQuantity() {
    if (quantity === responseData.productQuantity) {
      return;
    } else {
      setQuantity(quantity + 1);
    }
  }

  function decreaseQuantity() {
    if (quantity === 1) {
      return;
    } else {
      setQuantity(quantity - 1);
    }
  }

  useEffect(() => {
    dispatch(fetchProductDetail(id))
      .then((res) => {
        setResponseData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch,id]);

  return (
    <>
      <NavMenu />
      <SectionHeader text="Product Detail" />

      {/* screen size greater than 764px   */}
      {loading ? (
        <Loader />
      ) : (
        <>
          {!responseData ? (
            <div className="w-screen h-[30vh] flex justify-center items-center">
              <p>Product Detail not found</p>
            </div>
          ) : (
            <>
              <section className="hidden sm:flex px-3 sm:px-5 md:px-8 justify-center items-start">
                <div className="w-1/2">
                  <div className="w-full max-w-[600px] sm:h-[320px] md:h-[430px] lg:h-[500px] rounded-lg">
                    <img
                      className="w-full h-full rounded-lg"
                      src={responseData.productImage[0]}
                      alt="Product Review here"
                    />
                  </div>
                  <h5 className="poppin-500 text-[20px] my-4">
                    Product Description
                  </h5>
                  <p>{responseData.productDescription}</p>
                </div>
                <div className="w-1/2 sm:px-4 md:px-7 lg:px-10">
                  <h1 className="sm:text-[30px] md:text-[34px] lg:text-[40px] poppin-500 sm:pt-2 md:pt-10 lg:pt-14">
                    {responseData.productName}
                  </h1>
                  {/* <h5 className="sm:text-[26px] md:text-[30px] lg:text-[34px] poppin-500 py-4">
                    Sneakers
                  </h5> */}
                  <h5 className="sm:text-[26px] md:text-[30px] lg:text-[34px] poppin-500 py-4 text-[--blue]">
                    ₦{responseData.productSellingPrice}
                  </h5>
                  <div className="px-10 mt-4 text-center py-2 rounded-full bg-white w-max">
                    <p>{responseData.productQuantity} in stock</p>
                  </div>
                  <div className="px-3 mt-6 text-center py-2 rounded-full bg-white w-max flex justify-center items-center gap-10">
                    <div className="flex justify-center items-center">
                      <button
                        onClick={decreaseQuantity}
                        className="w-[40px] h-[40px] rounded-full bg-[--gray] text-[32px] flex justify-center items-center transition-all ease-in-out duration-300 hover:text-white hover:bg-red-700"
                      >
                        -
                      </button>
                      <p className="w-[60px] md:w-[70px] lg:w-[90px] text-center">
                        {Number(responseData.productQuantity)
                          ? quantity
                          : responseData.productQuantity}
                      </p>
                      <button
                        onClick={addQuantity}
                        className="w-[40px] h-[40px] rounded-full bg-[--gray] text-[32px] flex justify-center items-center transition-all ease-in-out duration-300 hover:text-white hover:bg-green-700"
                      >
                        +
                      </button>
                    </div>
                    {Number(responseData.productQuantity) ? (
                      ""
                    ) : (
                      <div className="text-red-500 border-black">
                        Zero In Stoke
                      </div>
                    )}
                    <button
                      onClick={handleAddToCart}
                      className="px-5 py-2 sm:text-[14px] md:text-[16px] lg:text-[20px] rounded-full bg-[--blue] text-white transition-all border border-[--blue] duration-300 ease-in-out hover:text-[--blue]  hover:bg-white"
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </section>

              {/* screen size less than 764px   */}

              <div className="sm:hidden mx-auto w-[90%] h-[300px]">
                <img
                  className="w-full h-full rounded-lg"
                  src={responseData.productImage[0]}
                  alt="Product Review here"
                />
              </div>
              <div className="w-[90%] mx-auto mt-5 border-b pb-5 block sm:hidden">
                <div className="flex justify-between items-center">
                  <h1 className="poppin-500 text-[20px]">
                    {responseData.productName}
                  </h1>
                  <div className="flex justify-center items-center px-5 py-2 bg-white rounded-full">
                    <p className="text-[14px] text-[--blue] poppin-500">
                      {responseData.productQuantity} in stock
                    </p>
                  </div>
                </div>
                {/* <p className="text-[16px] mt-1">Sneaker</p> */}
                <p className="text-[16px] mt-1 text-[--blue] poppin-500">
                  ₦ {responseData.productSellingPrice}
                </p>
              </div>
              <div className="block w-[90%] mx-auto sm:hidden">
                <h1 className="text-black mt-3 mb-2">Product Description</h1>
                <p className="pr-2 text-[14px]">
                  {responseData.productDescription}
                </p>
                <div className="px-3 mt-6 text-center py-2 rounded-full bg-white w-full flex justify-between items-center gap-6">
                  <div className="flex justify-center items-center">
                    <button
                      onClick={decreaseQuantity}
                      className="w-[30px] h-[30px] rounded-full bg-[--gray] text-[24px] flex justify-center items-center transition-all ease-in-out duration-300 hover:text-white hover:bg-red-700"
                    >
                      -
                    </button>
                    <p className="w-[50px] text-[14px] text-center">
                      {Number(responseData.productQuantity)
                        ? quantity
                        : responseData.productQuantity}
                    </p>
                    <button
                      onClick={addQuantity}
                      className="w-[30px] h-[30px] rounded-full bg-[--gray] text-[24px] flex justify-center items-center transition-all ease-in-out duration-300 hover:text-white hover:bg-green-700"
                    >
                      +
                    </button>
                  </div>
                  {Number(responseData.productQuantity) ? (
                    ""
                  ) : (
                    <div className="text-red-500 border-black">
                      Zero In Stoke
                    </div>
                  )}
                  <button
                    onClick={handleAddToCart}
                    className="px-5 py-2 text-[14px] rounded-full bg-[--blue] text-white transition-all border border-[--blue] duration-300 ease-in-out hover:text-[--blue]  hover:bg-white"
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <ButtonBack url={`/${storeName}`} />
      <Footer />
    </>
  );
};

export default ProductDetail;
