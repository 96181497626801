import React from "react";

const SectionHeader = ({ text, children }) => {
  return (
    <div className="px-3 sm:px-5 md:px-8 flex justify-between items-center pt-10 pb-3">
      <h1 className="poppin-700 text-[18px] sm:text-[20px] md:text-[24px]">
        {text}
      </h1>
      {children}
    </div>
  );
};

export default SectionHeader;
