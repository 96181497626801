import React, { useState } from "react";
import Input from "../Components/Input/Input";
// import { IoMdMail } from "react-icons/io";
import ButtonLoader from "../Components/Button/ButtonLoader";
import OtpInput from "../Components/Input/OtpInput";
import logo from "../Assets/Images/logo.png";
import { sendOtp, setPassword, validateOtp } from "../Redux/actions";
import { useDispatch } from "react-redux";
import Modal from "../Components/Modal/Modal";
import tick from "../Assets/Images/tick.png";
import { useNavigate } from "react-router-dom";
import { CiLock, CiMail } from "react-icons/ci";
import InputWrapper from "../Components/Wrapper/InputWrapper";
import InputPassword from "../Components/Input/InputPassword";

function ForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState(null);
  const [firstPassword, setFirstPassword] = useState(null);
  const [secondPassword, setSecondPassword] = useState(null);
  const [isResend, setIsResend] = useState(false);
  const [otp, setOtp] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [sendOtpProcessing, setSendOtpProcessing] = useState(false);
  const [sendOtpProcessingResponse, setSendOtpProcessingResponse] =
    useState(false);
  const [processingValidateOtp, setProcessingValidateOtp] = useState(false);
  const [changePasswordProcessing, setChangePasswordProcessing] =
    useState(false);
  const [resendOtpProcessing, setResendOtpProcessing] = useState(false);
  const [modal, setModal] = useState(false);
  /* handle input changes */
  const handleEmailChange = (event) => {
    setError(null);
    setEmail(event.target.value);
  };
  const handleOtpChange = (otp) => {
    setError(null);
    setOtp(otp);
  };
  const handleFirstPasswordChange = (event) => {
    setError(null);
    setFirstPassword(event.target.value);
  };
  const handleSecondPasswordChange = (event) => {
    setError(null);

    setSecondPassword(event.target.value);
  };
  /* utility func */
  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  /* handle clicks */
  const handleSendOtp = () => {
    const fetchSentOtpResponse = async () => {
      if (validateEmail(email)) {
        setSendOtpProcessing(true);
        const response = await dispatch(
          sendOtp({
            email,
          })
        );
        if (response.status === "success") {
          setSendOtpProcessing(false);
          setStep(2);
          setSendOtpProcessingResponse(response);
          setError("");
        }
        if (response.status === "fail") {
          setSendOtpProcessing(false);
          setError(response.message);
        }
      } else {
        setError("Please enter valid email");
      }
    };
    fetchSentOtpResponse();
  };
  const handleValidateOtp = () => {
    const otpValidation = async () => {
      if (otp) {
        setProcessingValidateOtp(true);
        const response = await dispatch(
          validateOtp({
            otp,
            encryptOpts: sendOtpProcessingResponse.data.otp,
          })
        );
        if (response.status === "success") {
          setProcessingValidateOtp(false);
          setError("");
          setStep(3);
        }
        if (response.status === "fail") {
          setIsResend(true);
          setProcessingValidateOtp(false);
          setError(response.message);
        }
      } else {
        setError("plz enter otp first");
      }
    };
    otpValidation();
    // setStep(3);
  };
  const handleResentOtp = () => {
    setIsResend(false);
    const fetchSentOtpResponse = async () => {
      if (validateEmail(email)) {
        setResendOtpProcessing(true);
        const response = await dispatch(
          sendOtp({
            email,
          })
        );
        if (response.status === "success") {
          setResendOtpProcessing(false);
          setStep(2);
          setSendOtpProcessingResponse(response);
          setError("");
          setSuccessMessage(`Otp sent to you email ${email}`);
          setTimeout(() => {
            setSuccessMessage(null);
          }, 4000);
        }
        if (response.status === "fail") {
          setResendOtpProcessing(false);
          setError(response.message);
        }
      } else {
        setError("plz enter the valid email");
      }
    };
    fetchSentOtpResponse();
  };
  const handleChangePassword = () => {
    if (firstPassword === secondPassword) {
      const changePassword = async () => {
        if (validateEmail(email)) {
          setChangePasswordProcessing(true);
          const response = await dispatch(
            setPassword({
              email,
              encryptOpts: sendOtpProcessingResponse.data.otp,
              otp,
              newPassword: firstPassword,
            })
          );
          if (response.status === "success") {
            setChangePasswordProcessing(false);
            setError("");
            setModal("true");
          }
          if (response.status === "fail") {
            setChangePasswordProcessing(false);
            setError(response.message);
          }
        } else {
          setError("plz enter the valid email");
        }
      };
      changePassword();
    } else {
      setError("Your confirm password is not matched");
    }
  };

  /* jsx */
  return step === 1 ? (
    <div className="w-screen flex h-screen justify-center items-center flex-col">
      <div className="w-screen sm:w-[90%] md:w-[70%]  rounded-3xl sm:rounded-lg p-4 px-8 ">
        <div className="mb-10 flex justify-center items-center">
          <img src={logo} alt={"this is nesmaspoint logo"} />
        </div>
        <div className="w-full bg-white p-8">
          <div className="w-full py-2">
            <h1 className="text-[--blue] poppin-700 text-[17px] sm:text-[20px] md:text-[24px]">
              Email Verification
            </h1>
          </div>
          <div>
            <p className="poppin-500 text-[13px] sm:text-[15px]">
              Enter valid email to reset passwword ?
            </p>
          </div>
          <div className="w-full mt-8">
            <Input
              type={"email"}
              name={"email"}
              placeholder={"enter email"}
              icon={<CiMail />}
              label={"Email"}
              onChange={handleEmailChange}
            />
          </div>
          <div className="mx-4 text-red-600">{error ? error : ""}</div>
          <div className="flex justify-center w-full mt-8 text-wrap">
            <ButtonLoader
              onClick={handleSendOtp}
              processing={sendOtpProcessing}
              disabled={sendOtpProcessing} // Disable button when processing
            >
              Enter
            </ButtonLoader>
          </div>
        </div>
      </div>
    </div>
  ) : step === 2 ? (
    <div className="w-screen flex h-screen justify-center items-center flex-col">
      <div className="w-screen sm:w-[90%] md:w-[70%]  rounded-3xl sm:rounded-lg p-4 px-8 ">
        <div className="mb-10 flex justify-center items-center">
          <img src={logo} alt={"this is nesmaspoint logo"} />
        </div>
        <div className="w-full bg-white p-8">
          <div className="w-full py-2">
            <h1 className="text-[--blue] poppin-700 text-[17px] sm:text-[20px] md:text-[24px]">
              OTP Verification
            </h1>
          </div>
          <div>
            <p className="poppin-500 text-[13px] sm:text-[15px]">
              Enter OTP you have recieved on email
            </p>
          </div>
          <div className="mt-7">
            <OtpInput numInputs={6} onOtpChange={handleOtpChange} />
          </div>
          <div className="mx-4 mt-4 text-red-600 text-center capitalize">
            {error ? error : ""}
          </div>
          <div className="mx-4 mt-4 text-green-600 text-center">
            {successMessage ? successMessage : ""}
          </div>
          <div className="mx-auto flex justify-center w-[400px] mt-10">
            {isResend ? (
              <ButtonLoader
                onClick={handleResentOtp}
                processing={resendOtpProcessing}
                disabled={resendOtpProcessing} // Disable button when processing
                className="bg-slate-400 rounded-none items-center py-0"
              >
                Resend OTP
              </ButtonLoader>
            ) : null}
          </div>
          <div className="flex justify-center mt-4 w-[400px] mx-auto">
            <ButtonLoader
              onClick={handleValidateOtp}
              processing={processingValidateOtp}
              disabled={processingValidateOtp} // Disable button when processing
            >
              validate otp
            </ButtonLoader>
          </div>
        </div>
      </div>
    </div>
  ) : step === 3 ? (
    <>
      {modal ? (
        <Modal>
          <div className="w-[92%] sm:w-[80%] md:w-[65%] lg:w-1/2 max-w-[500px] bg-white px-3 py-6 sm:px-6 rounded-lg flex justify-center items-center flex-col">
            <div className=" w-[120px] h-[120px] sm:w-[160px] sm:h-[160px] md:w-[200px] md:h-[200px]">
              <img className="w-full h-full" src={tick} alt="tick here" />
            </div>
            <p className="mt-8 text-[--blue] text-center poppin-600 text-[14px] sm:text-[16px]">
              Your password set successfully
            </p>
            <button
              onClick={() => {
                navigate(`/login`);
              }}
              className="mt-5 px-12 border border-[--blue] bg-[--blue] py-2 rounded-full text-white text-[18px] transition-all duration-300 ease-in-out hover:bg-white hover:text-[--blue]"
            >
              Done
            </button>
          </div>
        </Modal>
      ) : (
        ""
      )}
      <div className="w-screen flex h-screen justify-center items-center flex-col">
        <div className="w-screen sm:w-[90%] md:w-[70%]  rounded-3xl sm:rounded-lg p-4 px-8 ">
          <div className="mb-10 flex justify-center items-center">
            <img src={logo} alt={"this is nesmaspoint logo"} />
          </div>
          <div className="w-full bg-white p-8">
            <div className="w-full py-2">
              <h1 className="text-[--blue] poppin-700 text-[17px] sm:text-[20px] md:text-[24px]">
                Password Recovery
              </h1>
            </div>
            <div>
              <p className="poppin-500 text-[13px] sm:text-[15px]">
                Enter new password
              </p>
            </div>
            <InputWrapper customclasses="mt-6">
              <InputPassword
                name="password"
                label="Password"
                placeholder="Enter your password"
                icon={<CiLock />}
                onChange={handleFirstPasswordChange}
                value={firstPassword}
              />
            </InputWrapper>
            <InputWrapper customclasses="mt-6">
              <InputPassword
                name="passwordsecond"
                label="Confirm Password"
                placeholder="Re-enter password"
                icon={<CiLock />}
                onChange={handleSecondPasswordChange}
                value={secondPassword}
              />
            </InputWrapper>
            {/* <div className="w-full">
              <Input
                type={"password"}
                name={"passwordfirst"}
                placeholder={"Enter Password"}
                label="Password"
                icon={<CiLock />}
                onChange={handleFirstPasswordChange}
              />
            </div> */}
            {/* <div className="w-full">
              <Input
                type={"password"}
                name={"passwordsecond"}
                placeholder={"Confirm Password"}
                icon={<CiLock />}
                label="Confirm Password"
                onChange={handleSecondPasswordChange}
              />
            </div> */}
            <div className="mx-4 mt-4 text-red-600">{error ? error : ""}</div>
            <div className="flex justify-center w-full mt-4 text-wrap">
              <ButtonLoader
                onClick={handleChangePassword}
                processing={changePasswordProcessing}
                disabled={changePasswordProcessing}
              >
                Change Password
              </ButtonLoader>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col justify-center items-center h-[100vh] w-full px-2 sm:w-1/2 sm:p-0 m-auto">
        <div className="mb-10">
          <img src={logo} alt={"this is nesmaspoint logo"} />
        </div>
        <div className="w-full">
          <Input
            type={"password"}
            name={"passwordfirst"}
            placeholder={"Enter Password"}
            icon={<RiLockPasswordLine className="text-blue-700" />}
            onChange={handleFirstPasswordChange}
          />
        </div>
        <div className="w-full">
          <Input
            type={"password"}
            name={"passwordsecond"}
            placeholder={"Confirm Password"}
            icon={<RiLockPasswordLine className="text-blue-700" />}
            onChange={handleSecondPasswordChange}
          />
        </div>
        <div className="mx-4 mt-4 text-red-600">{error ? error : ""}</div>
        <div className="flex justify-center w-full mt-4 text-wrap">
          <ButtonLoader
            onClick={handleChangePassword}
            processing={changePasswordProcessing}
            disabled={changePasswordProcessing}
          >
            Change Password
          </ButtonLoader>
        </div>
      </div> */}
    </>
  ) : (
    ""
  );
}

export default ForgetPassword;
