import React, { useContext, useEffect, useState } from "react";
import NavMenu from "../Components/Navbar/NavMenu";
import SectionHeader from "../Components/Header/SectionHeader";
import Footer from "../Components/Footer/Footer";
import { RiDeleteBin6Line } from "react-icons/ri";
import InfoCard from "../Components/Cards/InfoCard";
import TextBetween from "../Components/Wrapper/TextBetween";
// import { FaRegCopy } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../Context/cartContext";
import EmptyCart from "./EmptyCart";
import {
  calculateSubtotal,
  calculateTotal,
  handleAddQuantity,
  handleDecreaseQuantity,
} from "../Utils/cartFUnctions";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Loader from "../Components/Loading/Loader";
import { getStoreInfo } from "../Redux/actions";
import NoInternet from "./NoInternet";
import { useFormik } from "formik";
import { orderSchema } from "../Schema";

let Once = true;

const inputs = [
  {
    label: "Name",
    name: "clientName",
    type: "text",
    placeholder: "Enter Name",
  },
  {
    label: "Email",
    type: "email",
    name: "clientEmail",
    placeholder: "Enter Email",
  },
  {
    label: "Phone No",
    type: "tel",
    name: "clientPhoneNumber",
    placeholder: "Enter Phone Number",
  },
  {
    label: "Address",
    type: "text",
    name: "clientAddress",
    placeholder: "Enter Full Address",
  },
];

const CheckOut = () => {
  const { cart, setCart } = useContext(CartContext);
  const [internetError, setInternetError] = useState(false);
  const formValues = JSON.parse(localStorage.getItem("formValues"));
  const storeInfo = useSelector((state) => state.store.data);
  const auth = useSelector((state) => state.auth.auth);
  const authData = useSelector((state) => state.auth?.userData);
  const { loaderPrimary } = useSelector((state) => state.loader);

  // Corrected initialState
  const initialState = {
    clientName: authData
      ? `${authData.firstName} ${authData.lastName}`
      : formValues?.clientName || "",
    clientEmail: authData ? authData.email : formValues?.clientEmail || "",
    clientPhoneNumber: formValues?.clientPhoneNumber || "",
    clientAddress: formValues?.clientAddress || "",
    clientNote: formValues?.clientNote || "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeName = localStorage.getItem("storeName");
  const [isUpdate, setIsUpdate] = useState(false);
  const [subTotal, setSubTotal] = useState(calculateSubtotal());
  const [total, setTotal] = useState(
    calculateTotal(storeInfo[0]?.shippingMethod.fixedShippingFee)
  );
  const [shippingOption, setShippingOption] = useState("shipping");
  const [shippingFee, setShippingFee] = useState(
    storeInfo[0]?.shippingMethod.fixedShippingFee || null
  );

  const handleShippingChange = (option) => {
    setShippingOption(option);
    if (option === "shipping") {
      setShippingOption("shipping");
      setShippingFee(storeInfo[0]?.shippingMethod.fixedShippingFee);
      setIsUpdate(true);
    } else {
      setShippingOption("pickup");
      setShippingFee(null);
      setIsUpdate(true);
    }
  };

  function handleDelete(productId) {
    let updatedCart = cart.filter((product) => product._id !== productId);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    let SubTotal = calculateSubtotal();
    setSubTotal(SubTotal);
    setTotal(calculateTotal(SubTotal, shippingFee));
  }

  function handleQuantity(type, product) {
    if (type === "add") {
      const data = handleAddQuantity(product);
      setCart(data);
      setIsUpdate(true);
    } else {
      const data = handleDecreaseQuantity(product);
      setCart(data);
      setIsUpdate(true);
    }
  }

  const {
    values,
    touched,
    handleBlur,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialState,
    validationSchema: orderSchema,
    onSubmit: (values, action) => {
      const formValues = {
        clientName: values.clientName,
        clientEmail: values.clientEmail,
        clientPhoneNumber: values.clientPhoneNumber,
        clientAddress: values.clientAddress,
        clientNote: values.clientNote,
      };
      localStorage.setItem(
        "shippingFee",
        shippingOption === "shipping"
          ? storeInfo[0]?.shippingMethod.fixedShippingFee
          : null
      );
      localStorage.setItem("formValues", JSON.stringify(formValues));
      navigate("/payment-info");
    },
  });

  // UseEffect to update form values if authData loads later
  useEffect(() => {
    if (authData) {
      setFieldValue("clientName", `${authData.firstName} ${authData.lastName}`);
      setFieldValue("clientEmail", authData.email);
    }
  }, [authData, setFieldValue]);

  useEffect(() => {
    setSubTotal(calculateSubtotal);
    setIsUpdate(false);
    if (shippingOption === "shipping") {
      setTotal(calculateTotal(storeInfo[0]?.shippingMethod.fixedShippingFee));
    } else {
      setTotal(calculateTotal(0));
    }
  }, [isUpdate, shippingOption, storeInfo]);

  useEffect(() => {
    setShippingOption("shipping");
    setShippingFee(storeInfo[0]?.shippingMethod.fixedShippingFee);
    setTotal(
      calculateTotal(storeInfo[0]?.shippingMethod.fixedShippingFee || 0)
    );
  }, [storeInfo]);

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty()) {
        dispatch(getStoreInfo({ storeName }))
          .then((res) => {
            console.log("Hello welcome to store");
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              navigate("/NotFound");
            } else {
              setInternetError(true);
            }
          });
      }
    }
  }, [dispatch, navigate, storeName]);

  return (
    <>
      <NavMenu />
      {loaderPrimary ? (
        <Loader />
      ) : (
        <>
          <SectionHeader text="Checkout" />
          {cart.length ? (
            <>
              {internetError ? (
                <NoInternet />
              ) : (
                <>
                  {/* screen size greater than 764px   */}
                  <section className="w-full px-8 hidden md:flex justify-between gap-6 items-start">
                    <div className="w-1/2">
                      {cart.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="py-2 px-3 bg-white rounded-lg mt-3 flex gap-6 justify-center items-start md:w-full max-w-[600px]"
                          >
                            {/* checkout product image  */}
                            <div className="max-w-[140px] min-w-[100px] py-2 h-[130px] rounded-lg">
                              <img
                                className="w-full h-full rounded-lg"
                                src={item.productImage[0]}
                                alt="product"
                              />
                            </div>

                            {/* checkout product detail  */}
                            <div className="w-full flex justify-start items-start flex-col">
                              <div className="flex justify-end items-start w-full">
                                <RiDeleteBin6Line
                                  onClick={() => {
                                    handleDelete(item._id);
                                  }}
                                  className="text-xl cursor-pointer transition-all duration-300 ease-in-out hover:text-red-600"
                                />
                              </div>
                              <h1 className="poppin-600 text-[20px] capitalize">
                                {item.productName}
                              </h1>
                              {/* <h3 className="mt-2 poppin-500 text-[18px]">Sneakers</h3> */}
                              <div className="flex justify-between gap-8 items-center w-full mt-2">
                                <h6 className="text-[--blue] md:text-[15px] lg:text-[18px]">
                                  ₦ {item.productSellingPrice}
                                </h6>
                                <div className="flex justify-center items-center">
                                  <p className="text-center text-[13px] poppin-500">
                                    In stock:
                                    <span className="ml-1 text-[--blue]">
                                      {item.productQuantity}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-between gap-8 items-center w-full mt-2">
                                <h3 className="mt-2 poppin-500 md:text-[12px] lg:text-[14px]">
                                  Total : ₦
                                  {item.quantity * item.productSellingPrice}
                                </h3>
                                <div className="flex justify-center items-center w-max">
                                  <button
                                    onClick={() => {
                                      handleQuantity("decrease", item);
                                    }}
                                    className="w-[20px] h-[20px] xl:w-[25px] xl:h-[25px] rounded-full bg-[--gray] text-[18px] xl:text-[23px] flex justify-center items-center transition-all ease-in-out duration-300 hover:text-white hover:bg-red-700"
                                  >
                                    -
                                  </button>
                                  <p className="w-[30px] xl:w-[60px] text-center">
                                    {item.quantity}
                                  </p>
                                  <button
                                    onClick={() => {
                                      handleQuantity("add", item);
                                    }}
                                    className="w-[20px] h-[20px] xl:w-[25px] xl:h-[25px] rounded-full bg-[--gray] text-[18px] xl:text-[23px] flex justify-center items-center transition-all ease-in-out duration-300 hover:text-white hover:bg-green-700"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="md:w-1/2 lg:w-[40%]">
                      <InfoCard header="Payment">
                        <div className="px-3 py-2">
                          <TextBetween
                            text="Sub Total"
                            value={`₦ ${subTotal}`}
                          />
                          <div className="flex justify-between items-center">
                            <div className="flex justify-start items-center py-2">
                              <input
                                type="checkbox"
                                id="shipping"
                                name="shipping"
                                className="w-[20px] h-[20px]"
                                value={shippingOption}
                                checked={shippingOption === "shipping"}
                                onChange={() =>
                                  handleShippingChange("shipping")
                                }
                              />
                              <label
                                htmlFor="shipping"
                                className="poppin-500 text-[14px] lg:text-[16px] ml-3"
                              >
                                Fixed Shipping Fee
                              </label>
                            </div>
                            <h2 className="poppin-500 text-[12px] sm:text-[14px] lg:text-[16px]">
                              ₦ {storeInfo[0]?.shippingMethod.fixedShippingFee}
                            </h2>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="flex justify-start items-center py-2">
                              <input
                                type="checkbox"
                                id="pickup"
                                name="shipping"
                                className="w-[20px] h-[20px]"
                                value={shippingOption}
                                checked={shippingOption === "pickup"}
                                onChange={() => handleShippingChange("pickup")}
                              />
                              <label
                                htmlFor="pickup"
                                className="poppin-500 text-[14px] lg:text-[16px] ml-3"
                              >
                                Customer Pick-Up
                              </label>
                            </div>
                            <h2 className="poppin-500 text-[12px] sm:text-[14px] lg:text-[16px]">
                              Free
                            </h2>
                          </div>
                          {/* <p className="py-2 text-[--red] text-[14px] lg:text-[16px] poppin-500">
                PLEASE, CONTACT THE SELLERON
              </p>
              <div
                onClick={handleClick}
                className="w-full flex justify-between items-center cursor-pointer"
              >
                <p
                  id="contact"
                  className="py-2  text-[14px] lg:text-[16px] poppin-500"
                >
                  +8801763372216
                </p>
                <FaRegCopy className="text-xl" />
              </div> */}
                          {/* <TextBetween text="Shipping Fee" value="₦ 1000.00" /> */}
                        </div>

                        {/* <div className="border-t px-3 py-2">
              <p className="pt-2 pb-3 text-[--red] text-[14px] lg:text-[16px] poppin-500">
                FOR SHIPPING FEE AND MANUALLY INPUT THE SHIPPING FEE AS AGREED
                WITH THE SELLER!
              </p>
            </div> */}
                        <div className="border-t px-3 py-3">
                          <TextBetween
                            text="Total Amount"
                            value={`₦ ${total}`}
                          />
                        </div>
                      </InfoCard>

                      <InfoCard header="Address" customClasses="mt-6">
                        <div className="px-3 py-2">
                          {inputs.map((inpt, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="flex justify-center items-start flex-col py-4"
                                >
                                  <div className="w-full">
                                    <input
                                      className="w-full border-b border-black outline-none px-2"
                                      type={inpt.type}
                                      placeholder={inpt.placeholder}
                                      name={inpt.name}
                                      value={values[inpt.name]}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  {touched[inpt.name] && errors[inpt.name] ? (
                                    <span className="text-red-500 text-[14px]">
                                      {errors[inpt.name]}
                                    </span>
                                  ) : null}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </InfoCard>

                      <div className="w-full py-5">
                        <h1 className="text-[20px] poppin-600">Add Note</h1>
                      </div>
                      <textarea
                        name="clientNote"
                        id="note"
                        value={values.clientNote}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows="5"
                        className="w-full rounded-lg p-3 outline-none"
                        placeholder="Add note about the order"
                      ></textarea>
                      {touched.clientNote && errors.clientNote ? (
                        <span className="text-red-500 text-[14px]">
                          {errors.clientNote}
                        </span>
                      ) : null}
                    </div>
                  </section>

                  {/* screen size less than 764px   */}
                  <section className="w-full px-4 mx-auto block md:hidden">
                    {cart.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="mt-3 py-2 px-3 bg-white rounded-lg flex gap-3 sm:gap-6 justify-between items-start w-full"
                        >
                          {/* checkout product image  */}
                          <div className="w-[110p] h-[100px] sm:w-[140px] py-2 sm:h-[110px] rounded-lg">
                            <img
                              className="w-full h-full rounded-lg"
                              src={item.productImage[0]}
                              alt="product"
                            />
                          </div>

                          {/* checkout product detail  */}
                          <div className="w-full flex justify-start items-start flex-col">
                            <div className="flex justify-end items-start w-full">
                              <RiDeleteBin6Line
                                onClick={() => {
                                  handleDelete(item._id);
                                }}
                                className="text-lg cursor-pointer transition-all duration-300 ease-in-out hover:text-red-600"
                              />
                            </div>
                            <h1 className="poppin-600 text-[14px] sm:text-[16px] capitalize">
                              {item.productName}
                            </h1>
                            {/* <h3 className="mt-2 poppin-500 text-[12px] sm:text-[14px]">
                      Sneakers
                    </h3> */}
                            <div className="flex justify-between gap-8 items-center w-full mt-2">
                              <h6 className="text-[--blue] text-[12px] sm:text-[14px]">
                                ₦ {item.productSellingPrice}
                              </h6>
                              <div className="flex justify-center items-center">
                                {/* <button
                          onClick={() => {
                            if (quantity > 1) setQuantity(quantity - 1);
                          }}
                          className="w-[18px] h-[18px] sm:w-[25px] sm:h-[25px] rounded-full bg-[--gray] text-[17px] sm:text-[20px] flex justify-center items-center transition-all ease-in-out duration-300 hover:text-white hover:bg-red-700"
                        >
                          -
                        </button> */}
                                <p className="text-center text-[13px] poppin-500">
                                  In stock:
                                  <span className="ml-1 text-[--blue]">
                                    {item.productQuantity}
                                  </span>
                                </p>
                                {/* <button
                          onClick={() => {
                            setQuantity(quantity + 1);
                          }}
                          className="w-[18px] h-[18px] sm:w-[25px] sm:h-[25px] rounded-full bg-[--gray] text-[17px] sm:text-[20px] flex justify-center items-center transition-all ease-in-out duration-300 hover:text-white hover:bg-green-700"
                        >
                          +
                        </button> */}
                              </div>
                            </div>

                            <div className="flex justify-between gap-8 items-center w-full mt-2">
                              <h3 className="mt-2 poppin-500 md:text-[12px] lg:text-[14px]">
                                Total : ₦
                                {item.quantity * item.productSellingPrice}
                              </h3>
                              <div className="flex justify-center items-center w-max">
                                <button
                                  onClick={() => {
                                    handleQuantity("decrease", item);
                                  }}
                                  className="w-[20px] h-[20px] xl:w-[25px] xl:h-[25px] rounded-full bg-[--gray] text-[18px] xl:text-[23px] flex justify-center items-center transition-all ease-in-out duration-300 hover:text-white hover:bg-red-700"
                                >
                                  -
                                </button>
                                <p className="w-[30px] xl:w-[60px] text-center">
                                  {item.quantity}
                                </p>
                                <button
                                  onClick={() => {
                                    handleQuantity("add", item);
                                  }}
                                  className="w-[20px] h-[20px] xl:w-[25px] xl:h-[25px] rounded-full bg-[--gray] text-[18px] xl:text-[23px] flex justify-center items-center transition-all ease-in-out duration-300 hover:text-white hover:bg-green-700"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="w-full mt-3">
                      <InfoCard header="Payment">
                        <div className="px-2 md:px-3 py-2">
                          <TextBetween
                            text="Sub Total"
                            value={`₦ ${subTotal}`}
                          />
                          {/* <TextBetween text="Discount" value="₦ 0.00" />
                      <TextBetween text="Tax" value="₦ 0.00" /> */}
                          <div className="flex justify-between items-center">
                            <div className="flex justify-start items-center py-2">
                              <input
                                type="checkbox"
                                id="pickup"
                                name="shipping"
                                className="w-[20px] h-[20px]"
                                value={shippingOption}
                                checked={shippingOption === "shipping"}
                                onChange={() =>
                                  handleShippingChange("shipping")
                                }
                              />
                              <label
                                htmlFor="shipping"
                                className="poppin-500 text-[12px] sm:text-[14px] lg:text-[16px] ml-3"
                              >
                                Fixed Shipping Fee
                              </label>
                            </div>
                            <h2 className="poppin-500 text-[12px] sm:text-[14px] lg:text-[16px]">
                              ₦ {storeInfo[0]?.shippingMethod.fixedShippingFee}
                            </h2>
                          </div>
                          <div className="flex justify-between items-center">
                            <div className="flex justify-start items-center py-2">
                              <input
                                type="checkbox"
                                id="pickup"
                                name="shipping"
                                className="w-[20px] h-[20px]"
                                value={shippingOption}
                                checked={shippingOption === "pickup"}
                                onChange={() => handleShippingChange("pickup")}
                              />
                              <label
                                htmlFor="pickup"
                                className="poppin-500 text-[12px] sm:text-[14px] lg:text-[16px] ml-3"
                              >
                                Customer Pick-Up
                              </label>
                            </div>
                            <h2 className="poppin-500 text-[12px] sm:text-[14px] lg:text-[16px]">
                              Free
                            </h2>
                          </div>
                          {/* <p className="py-2 text-[--red] text-[12px] sm:text-[14px] lg:text-[16px] poppin-500">
                PLEASE, CONTACT THE SELLERON
              </p> */}
                          {/* <div
                onClick={handleClick}
                className="w-full flex justify-between items-center cursor-pointer"
              >
                <p
                  id="contact"
                  className="py-2  text-[14px] lg:text-[16px] poppin-500"
                >
                  +8801763372216
                </p>
                <FaRegCopy className="text-xl" />
              </div> */}
                          {/* <TextBetween text="Shipping Fee" value="₦ 1000.00" /> */}
                        </div>
                        {/* <div className="border-t px-3 py-2">
              <p className="pt-2 pb-3 text-[--red] text-[12px] sm:text-[14px] lg:text-[16px] poppin-500">
                FOR SHIPPING FEE AND MANUALLY INPUT THE SHIPPING FEE AS AGREED
                WITH THE SELLER!
              </p>
            </div> */}
                        <div className="border-t px-3 py-3">
                          <TextBetween
                            text="Total Amount"
                            value={`₦ ${total}`}
                          />
                        </div>
                      </InfoCard>

                      <InfoCard header="Address" customClasses="mt-3">
                        <div className="px-3 py-2">
                          {inputs.map((inpt, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="flex justify-center items-start flex-col py-4"
                                >
                                  {/* <div className="min-w-[80px]">
                                    <p className="poppin-500 text-[16px]">
                                      {inpt.label}
                                    </p>
                                  </div> */}
                                  {/* <div className="px-3">
                                    <p className="poppin-500 text-[18px]">:</p>
                                  </div> */}
                                  <div className="w-full">
                                    <input
                                      className="w-full border-b border-black outline-none px-2"
                                      type={inpt.type}
                                      placeholder={inpt.placeholder}
                                      name={inpt.name}
                                      value={values[inpt.name]}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  {touched[inpt.name] && errors[inpt.name] ? (
                                    <span className="text-red-500 text-[14px]">
                                      {errors[inpt.name]}
                                    </span>
                                  ) : null}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </InfoCard>

                      <div className="w-full py-3">
                        <h1 className="text-[16px] sm:text-[18px] md:text-[20px] poppin-600">
                          Add Note
                        </h1>
                      </div>
                      <textarea
                        name="clientNote"
                        id="note"
                        rows="5"
                        className="w-full rounded-lg p-3 outline-none text-[14px] sm:text-[16px]"
                        placeholder="Add note about the order"
                        value={values.clientNote}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></textarea>
                      {touched.clientNote && errors.clientNote ? (
                        <span className="text-red-500 text-[14px]">
                          {errors.clientNote}
                        </span>
                      ) : null}
                    </div>
                  </section>

                  {/* footer button   */}
                  <div className="w-full flex justify-center items-center pt-10 pb-5">
                    {auth ? (
                      storeInfo[0]?.bankDetail ? (
                        <button
                          onClick={handleSubmit}
                          className="px-24 border border-[--blue] bg-[--blue] py-2 rounded-full text-white text-[18px] transition-all duration-300 ease-in-out hover:bg-white hover:text-[--blue]"
                        >
                          Continue
                        </button>
                      ) : (
                        <div className="bg-[--red] text-[#ffffff] p-4">
                          Please contact the store owner for payment details
                        </div>
                      )
                    ) : (
                      <Link
                        to="/login"
                        className="px-24 border border-[--blue] bg-[--blue] py-2 rounded-full text-white text-[18px] transition-all duration-300 ease-in-out hover:bg-white hover:text-[--blue]"
                      >
                        Login
                      </Link>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <EmptyCart />
          )}
        </>
      )}

      <Footer />
    </>
  );
};

export default CheckOut;
