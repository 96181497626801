import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdClock } from "react-icons/io";

const TimeOut = () => {
    const navigate = useNavigate();
    const { pendingPage } = useParams();

    const RetryFunc = () => {
        console.log(decodeURIComponent(pendingPage));
        navigate(decodeURIComponent(pendingPage));
    };

    return (
         <div className="container mx-auto flex items-center justify-center min-h-screen">
      <div className="w-full max-w-md p-4 rounded-lg shadow-md bg-white dark:bg-gray-800">
                <div className="flex items-center justify-around mb-4">
                    <div className="text-[80px]"><IoMdClock /></div>
          <h2 className="text-2xl font-bold text-red-700">Request timed out</h2>
        </div>
        <p className="mb-4 text-gray-600 dark:text-gray-400">
          the server not give response right now click try Again
                </p>
            <button onClick={RetryFunc} className="p-4 bg-blue-700 text-white rounded-lg hover:opacity-95 cursor-pointer">Retry</button>
      </div>
    </div>
    );
};

export default TimeOut;
