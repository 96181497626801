import React from "react";
import { Link } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";

const ButtonBack = ({ url, customclasses }) => {
  return (
    <Link
      to={url}
      className={`w-[150px] rounded-full text-[--blue] border border-[--blue]  py-2 px-7 mt-5  mx-auto flex justify-between items-center transition-all ease-in-out hover:bg-[--blue] hover:text-white ${customclasses}`}
    >
      <IoArrowBackOutline className="text-[26px]" />
      <p>Back</p>
    </Link>
  );
};

export default ButtonBack;
