import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Store from "../Pages/Store";
import ProductDetail from "../Pages/ProductDetail";
import CheckOut from "../Pages/CheckOut";
import PaymentInfo from "../Pages/PaymentInfo";
import Login from "../Pages/Auth/Login";
import Register from "../Pages/Auth/Register";
import OrderDetail from "../Pages/OrderDetail";
import About from "../Pages/About";
import NotFound from "../Pages/NotFound/NotFound";
import Orders from "../Pages/Orders";
import ForgetPassword from "../Pages/ForgetPassword";
import TimeOut from "../Pages/TimeOut/TimeOut";
export default createBrowserRouter([
  {
    path: `/:storeName`,
    element: <Store />,
  },
  {
    path: "/:storeName/product-detail/:id",
    element: <ProductDetail />,
  },
  {
    path: "/checkout",
    element: <CheckOut />,
  },
  {
    path: "/payment-info",
    element: <PaymentInfo />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup/:storeName",
    element: <Register />,
  },
  {
    path: "/orders",
    element: <Orders />,
  },
  {
    path: "/order-detail/:orderId",
    element: <OrderDetail />,
  },
  {
    path: `/about-us/:storeName`,
    element: <About />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: `/timeout-page/:pendingPage`,
    element: <TimeOut />,
  },
  {
    path: `/NotFound`,
    element: <NotFound />,
  },
  {
    path: `*`,
    element: <NotFound />,
  },
]);
