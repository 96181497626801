import React from "react";

const Input = ({
  type,
  name,
  placeholder,
  icon,
  label,
  onChange,
  value,
  onBlur,
  error,
  touch,
}) => {
  return (
    <div className="flex-grow basis-72">
      <label className="poppin-500 text-[14px] " htmlFor={name}>
        {label}
      </label>
      <div className="w-full flex justify-start items-center border rounded-md mt-1">
        <div className="flex justify-center items-center text-lg sm:text-xl md:text-3xl px-2">
          {icon}
        </div>
        <input
          className="w-full outline-none py-2 sm:py-4 px-3 text-[14px] sm:text-[16px] rounded-lg"
          type={type}
          placeholder={placeholder}
          name={name}
          id={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>
      {error && touch ? (
        <span className="text-red-600 text-[14px] mt-1">{error}</span>
      ) : null}
    </div>
  );
};

export default Input;
