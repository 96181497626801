import React from "react";

const Modal = ({ children }) => {
  return (
    <section className="w-screen h-screen fixed top-0 left-0 z-[99] modalBg flex justify-center items-center">
      {children}
    </section>
  );
};

export default Modal;
