import React from "react";

const TextBetween = ({ text, value }) => {
  return (
    <div className="w-full flex justify-between items-center py-2">
      <h2 className="poppin-500 text-[12px] sm:text-[14px] lg:text-[16px]">
        {text}
      </h2>
      <h2 className="poppin-500 text-[12px] sm:text-[14px] lg:text-[16px]">
        {value}
      </h2>
    </div>
  );
};

export default TextBetween;
