const initialState = {
  data: [],
  singleOrder: {},
};

const orderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "ALL_ORDER":
      return {
        ...state,
        data: payload.data,
      };
    case "INSERT_ORDER":
      return {
        ...state,
        data: [payload?.data, ...state.data],
      };
    case "GET_SINGLE_ORDER":
      return {
        ...state,
        singleOrder: state.data.filter((order) => order._id === payload),
      };
    case "LOGOUT":
      return initialState; // Reset orders state on logout
    default:
      return state;
  }
};

export default orderReducer;
