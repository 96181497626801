import React, { useContext, useEffect, useState } from "react";
import AvatarName from "../Avatar/AvatarName";
import { HiOutlineShoppingBag } from "react-icons/hi";
// import { GiHamburgerMenu } from "react-icons/gi";y
import NavItems from "./NavItems";
import { Link, useParams } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { CartContext } from "../../Context/cartContext";
import { isEmpty } from "lodash";
import { getStoreInfo } from "../../Redux/actions";

const NavMenu = () => {
const { storeName } = useParams();
  const dispatch=useDispatch()
  const storedValue = useSelector((state) => state.store.data[0]);
  const [menuOpen, setMenuOpen] = useState(false);
  const { cart } = useContext(CartContext);

  useEffect(() => {
    if (isEmpty(storedValue)) {
      dispatch(getStoreInfo({storeName}))
    }
  },[storedValue,dispatch,storeName])

  function handleMenu() {
    setMenuOpen(!menuOpen);
  }
  return (
    <>
      <nav className="w-full px-0  md::px-8 py-2 bg-white fixed top-0 left-0 z-50">
        <div className="min-h-[92px] px-2 sm:px-4  w-full flex justify-between items-center">
          <AvatarName />
          <div className="w-[45%] lg:w-[35%] px-4 hidden md:flex justify-center items-center">
            <div className="w-[96%] flex justify-between items-center">
              <NavItems />
            </div>
          </div>
          <div className="flex justify-center items-center gap-8 ">
            <Link to="/checkout" className="text-center relative">
              <HiOutlineShoppingBag className="text-xl sm:text-2xl md:text-3xl" />
              {cart.length ? (
                <span className="absolute top-[-20%] right-[-10%] w-[12px] h-[12px] sm:w-[15px] sm:h-[15px]  md:w-[18px] md:h-[18px] flex justify-center items-center rounded-full bg-red-500 text-white text-[8px] md:text-[10px]">
                  {cart.length}
                </span>
              ) : null}
            </Link>
            <div className="flex justify-center items-center md:hidden">
              <GiHamburgerMenu
                onClick={handleMenu}
                className="text-xl sm:text-2xl md:text-3xl"
              />
            </div>
          </div>
        </div>
        {menuOpen ? (
          <div className="w-full py-4 flex justify-center items-center flex-col md:hidden bg-white">
            <div className="w-[60px] h-[60px] rounded-full mb-2">
              <img
                className="w-full h-full rounded-full"
                src={storedValue.store.venderProfileImage}
                alt="User Avatar"
              />
            </div>
            <NavItems />
          </div>
        ) : (
          ""
        )}
      </nav>
      <div className="w-full h-[96px]"></div>
    </>
  );
};

export default NavMenu;
