import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavMenu from "../Components/Navbar/NavMenu";
import SectionHeader from "../Components/Header/SectionHeader";
import CardWrapper from "../Components/Wrapper/CardWrapper";
import ProductCard from "../Components/Cards/ProductCard";
import Footer from "../Components/Footer/Footer";
import { LuSearch } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";
import { getStoreInfo, products, searchProducts } from "../Redux/actions";
import Loader from "../Components/Loading/Loader";
import { CartContext } from "../Context/cartContext";
import { errorToast, successToast } from "../Utils/Toast";
import { addToCart } from "../Utils/cartFUnctions";
import { isEmpty } from "lodash";
import FilterHorizontal from "../Assets/Images/FilterHorizontal.png";
import ReactPaginate from "react-paginate";

let Once = true;

const Store = () => {
  const dispatch = useDispatch();
  const productsAre = useSelector((state) => state.product.data);
  const sort = useSelector((state) => state.product.sort);
  const auth = useSelector((state) => state.auth.auth);
  const storeInfo = useSelector((state) => state.store);
  const { storeName } = useParams();
  const currentPage = useSelector((state) => state.product.currentPage);
  const totalPages = useSelector((state) => state.product.totalPages);
  const [searchProduct, setSearchProduct] = useState([]);
  const [Filter, setFilter] = useState(false);
  const navigate = useNavigate();
  const { loaderPrimary, loaderSecondary } = useSelector(
    (state) => state.loader
  );

  const { cart, setCart } = useContext(CartContext);

  if (localStorage.getItem("storeName") !== storeName) {
    localStorage.removeItem("cart");
  }
  localStorage.setItem("storeName", storeName);

  const handleAddToCart = (product) => {
    addToCart(cart, product)
      .then((result) => {
        setCart(result);
        localStorage.setItem("cart", JSON.stringify(result));
        successToast("Added to cart");
      })
      .catch((error) => {
        errorToast("Out of Stock");
        console.log("Add to cart store page error ===>", error);
      });
  };

  useEffect(() => {
    if (Once) {
      Once = false;
      dispatch({ type: "PRIMARY_LOAD" });
      const fetchStore = async () => {
        const result = await dispatch(getStoreInfo({ storeName, auth }));
        dispatch(products({ storeName, page: currentPage }));
        if (result.data.status === "success") {
          dispatch({ type: "UNLOAD" });
        } else if (result.data.status === "fail") {
          if (result.status === 400) {
            navigate("/NotFound");
          } else {
            console.error("Store error ======>", result);
          }
        }
      };
      fetchStore();
      if (isEmpty(productsAre)) {
        dispatch(products({ storeName, page: currentPage }));
      }
    }
  }, [
    auth,
    currentPage,
    dispatch,
    navigate,
    productsAre,
    storeInfo.data,
    storeName,
  ]);

  const handlePageChange = (event) => {
    const selectedPage = event.selected + 1; // ReactPaginate uses zero-based index
    dispatch({
      type: "SET_CURRENT_PAGE",
      payload: selectedPage,
    });
    dispatch(
      products({
        storeName,
        page: selectedPage,
        sort: -1,
      })
    );
  };

  const makeFilterFalse = () => {
    setFilter(false);
  };

  const onChangeProductSearch = async (event) => {
    const searchTERM = event.target.value.trim();
    if (searchTERM !== "") {
      const searchedProducts = await dispatch(
        searchProducts({
          venderId: storeInfo.data[0].store.venderId,
          searchTerm: event.target.value,
        })
      );
      if (event.target.value.trim() !== "") {
        setSearchProduct(searchedProducts.data);
      } else {
        setSearchProduct([]);
      }
    } else {
      setSearchProduct([]);
    }
  };

  const filterButton = () => {
    setFilter(!Filter);
  };

  const filterChange = (e) => {
    if (e.target.id === "newToOld") {
      dispatch(products({ storeName, page: currentPage, sort: -1 }));
    }
    if (e.target.id === "oldToNew") {
      dispatch(products({ storeName, page: currentPage, sort: 1 }));
    }
  };

  return (
    <div>
      {loaderPrimary ? (
        <section className="flex justify-center items-center w-screen h-screen">
          <Loader />
        </section>
      ) : (
        <>
          <div onClick={makeFilterFalse}>
            <NavMenu />
          </div>
          <div>
            <div
              className={`mt-10 w-full justify-start items-center mx-auto`}
              onClick={makeFilterFalse}
            >
              <div className="w-[98%] sm:w-[80%] mx-auto flex justify-center items-center">
                <input
                  id="searchinput"
                  type="text"
                  className="py-2 px-3 text-[18px] w-[80%] outline-none rounded-tl-xl rounded-bl-xl"
                  placeholder="Search"
                  onChange={onChangeProductSearch}
                />
                <div className="flex justify-center items-center py-[11px] px-3 bg-[--blue] text-white rounded-tr-xl rounded-br-xl">
                  <LuSearch className="text-xl " />
                </div>
              </div>
            </div>
            {loaderSecondary ? (
              <Loader />
            ) : (
              <>
                <SectionHeader
                  text="Our Products"
                  className="flex flex-row-reverse"
                >
                  <div>
                    <button onClick={filterButton}>
                      <img
                        src={FilterHorizontal}
                        alt="filter button"
                        className="text-lg sm:text-2xl bg-white p-2 sm:p-4 rounded-md"
                      />
                    </button>
                  </div>
                </SectionHeader>
                <div className={`relative flex justify-end`}>
                  {Filter ? (
                    <section className="fixed right-0 mr-12 z-50">
                      <div className="bg-white flex flex-col w-[300px] items-center p-4">
                        <div className="font-bold">Product Sort</div>
                        <div>
                          <div className="flex justify-between items-center">
                            <div className="flex justify-start items-center py-2">
                              <input
                                type="radio"
                                id="newToOld"
                                name="productSort"
                                className="w-[20px] h-[20px]"
                                onChange={filterChange}
                                checked={sort === -1}
                              />
                              <label
                                htmlFor="shipping"
                                className="poppin-500 text-[14px] lg:text-[16px] ml-3"
                              >
                                New To Old product
                              </label>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="flex justify-start items-center py-2">
                              <input
                                type="radio"
                                id="oldToNew"
                                name="productSort"
                                className="w-[20px] h-[20px]"
                                onChange={filterChange}
                                checked={sort === 1}
                              />
                              <label
                                htmlFor="pickup"
                                className="poppin-500 text-[14px] lg:text-[16px] ml-3"
                              >
                                Old to New product
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`${Filter ? "opacity-25" : ""}`}
                  onClick={makeFilterFalse}
                >
                  <CardWrapper>
                    {(searchProduct.length
                      ? searchProduct
                      : productsAre.length
                      ? productsAre
                      : []
                    ).map((product, index) => (
                      <ProductCard
                        cartClick={(e) => {
                          e.preventDefault();
                          handleAddToCart(product);
                        }}
                        key={index}
                        product={product}
                      />
                    ))}
                  </CardWrapper>
                  {totalPages > 1 && (
                    <div className="flex justify-center items-center mt-4">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageChange}
                        forcePage={currentPage - 1}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        containerClassName="flex justify-center space-x-2 mt-4"
                        pageClassName="w-[40px] border rounded cursor-pointer"
                        pageLinkClassName="w-full h-full flex justify-center items-center"
                        previousClassName="px-3 py-1 border rounded cursor-pointer"
                        previousLinkClassName="w-full h-full flex justify-center items-center"
                        nextClassName="px-3 py-1 border rounded cursor-pointer"
                        nextLinkClassName="w-full h-full flex justify-center items-center"
                        breakClassName="px-3 py-1 border rounded cursor-pointer"
                        breakLinkClassName="w-full h-full flex justify-center items-center"
                        activeClassName="bg-blue-500 text-white"
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div onClick={makeFilterFalse}>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default Store;
