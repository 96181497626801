import React from "react";

const NotFound = () => {
  return (
    <div className="w-screen h-screen bg-[--bg-yellow-light] flex justify-center items-center flex-col">
      <h1 className="text-[60px] poppin-700">404</h1>
      <h5 className="text-[40px] poppin-700">OOPS!</h5>
      <p className="text-[25px]">Requested Page Not Found</p>
    </div>
  );
};

export default NotFound;
