import React from "react";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { Link } from "react-router-dom";
import { errorToast } from "../../Utils/Toast";

const ProductCard = ({ product, cartClick }) => {
  let storeName = localStorage.getItem("storeName");

  const handleCardClick = () => {
    if (!product.productQuantity) {
      errorToast("This product is out of stock");
    }
  };

  return (
    <div className="relative bg-white w-[140px] sm:w-[220px] md:w-[250px] rounded-lg">
      {!product.productQuantity && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 text-white px-3 py-1 rounded">
          Out of Stock
        </div>
      )}
      <Link
        onClick={handleCardClick}
        to={
          product.productQuantity
            ? `/${storeName}/product-detail/${product._id}`
            : `/${storeName}`
        }
        className={`block w-full h-full rounded-xl transition-opacity ${
          !product.productQuantity && "opacity-50"
        }`}
      >
        <div className="w-full h-[80px] sm:h-[130px] md:h-[160px] rounded-xl">
          <img
            key={product.productImage} // Add key prop here
            className="w-full h-full rounded-xl"
            src={product.productImage}
            alt=""
          />
        </div>
        <p className="mt-4 text-[14px] px-1 sm:text-[18px] capitalize">
          {product.productName}
        </p>
        <div className="w-full flex justify-between items-center px-1 mt-1">
          <p className="text-[--blue] text-[12px] sm:text-[16px]">
            ₦ {product.productSellingPrice}
          </p>
          <button
            onClick={cartClick}
            className="py-2 sm:py-3 px-2 sm:px-3 rounded-full bg-[--gray-light]"
          >
            <HiOutlineShoppingBag className="text-lg sm:text-2xl" />
          </button>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
