import React, { useEffect, useState } from "react";
import NavMenu from "../Components/Navbar/NavMenu";
import SectionHeader from "../Components/Header/SectionHeader";
import Footer from "../Components/Footer/Footer";
import InfoCard from "../Components/Cards/InfoCard";
import TextBetween from "../Components/Wrapper/TextBetween";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../Components/Loading/Loader";
import moment from "moment";
import { IoArrowBackOutline } from "react-icons/io5";

const inputs = [
  {
    label: "Name",
    name: "clientName",
    type: "text",
    placeholder: "Enter Name",
    value: "John Doe",
  },
  {
    label: "Email",
    type: "email",
    name: "clientEmail",
    placeholder: "Enter Email",
    value: "johndoe@example.com",
  },
  {
    label: "Phone No",
    type: "text",
    name: "clientPhoneNumber",
    placeholder: "Enter Phone Number",
    value: "07062231663",
  },
  {
    label: "Address",
    type: "text",
    name: "clientAddress",
    placeholder: "Enter Full Address",
    value: "Osagi 5, Akbor, Nigeria",
  },
];

const OrderDetail = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const selectedOrder = useSelector((state) => state.orders.singleOrder);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch({ type: "GET_SINGLE_ORDER", payload: orderId });
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [dispatch, orderId]);
  return (
    <>
      <NavMenu />
      <SectionHeader text="Order Detail" />
      {loading ? (
        <Loader />
      ) : (
        <>
          {selectedOrder.length ? (
            <>
              <section className="w-full px-6  md:px-8 pt-0 pb-4">
                <div className="w-full bg-white rounded-lg p-4 hidden sm:flex justify-between items-center">
                  <div className="w-max">
                    <p className="poppin-500 text-[14px]">
                      Order ID
                      <span className="text-[--blue]">
                        {" "}
                        #{selectedOrder[0]?._id}
                      </span>
                    </p>
                    <p className="text-gray-400 text-[14px] mt-2">
                      Order on{" "}
                      {moment(selectedOrder[0]?.orderCreated).format(
                        "DD MMM YYYY hh:mm:ss A"
                      )}
                    </p>
                  </div>
                  <h1 className="poppin-600 text-[18px]">
                    Total :{" "}
                    <span className="text-[--blue]">
                      ₦ {selectedOrder[0]?.totalAmount}
                    </span>
                  </h1>
                </div>

                {/* screen size greater than 764  */}
                <div className="w-full hidden md:flex justify-between items-start gap-10 mt-4">
                  {/* left cards wrapper  */}
                  <div className="w-[48%] lg:w-[40%]">
                    {selectedOrder[0]?.cart.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full mt-2 max-w-[650px] py-2 px-3 bg-white rounded-lg flex gap-6 justify-center items-start"
                        >
                          {/* checkout product image  */}
                          <div className="w-[160px]  py-2 h-[130px] rounded-lg">
                            <img
                              className="w-full h-full rounded-lg"
                              src={item.productImage[0]}
                              alt="Product Banner"
                            />
                          </div>

                          {/* checkout product detail  */}
                          <div className="w-full flex justify-start items-start flex-col pl-4">
                            <h1 className="poppin-600 text-[20px] capitalize pt-3">
                              {item.productName}
                            </h1>
                            <h3 className="mt-2 poppin-500 text-[18px]">
                              Quantity : {item.orderQuantity}
                            </h3>
                            <div className="flex justify-between gap-8 items-center w-full mt-2">
                              <h6 className="text-[--blue] text-[18px]">
                                ₦ {item?.productPrice}
                              </h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <InfoCard customClasses="!mt-4" header="Price Summary">
                      <div className="px-3 py-2">
                        <TextBetween
                          text="Sub Total"
                          value={`₦ ${selectedOrder[0].orderSubTotal}`}
                        />
                        {/* <TextBetween text="Discount" value="₦ 0.00" />
                        <TextBetween text="Tax" value="₦ 0.00" /> */}
                        <TextBetween
                          text="Shipping Fee"
                          value={`₦ ${
                            selectedOrder[0].orderFixedShippingFee || 0
                          }`}
                        />
                        <div className="border-t  py-1">
                          <TextBetween
                            text="Total Amount"
                            value={`₦ ${selectedOrder[0].totalAmount}`}
                          />
                        </div>
                      </div>
                    </InfoCard>
                  </div>

                  {/* right cards wrapper  */}
                  <div className="w-[48%] lg:w-[40%]">
                    <InfoCard header="Basic Information">
                      <div className="px-3 py-2">
                        <TextBetween
                          text="Order Date"
                          value={moment(selectedOrder[0]?.orderCreated).format(
                            "DD-MMM-YYYY"
                          )}
                        />
                        <TextBetween
                          text="Order No."
                          value={`#${selectedOrder[0]._id}`}
                        />
                        <TextBetween
                          text="Order Total"
                          value={`₦ ${selectedOrder[0].totalAmount}`}
                        />
                      </div>
                      {/* <div className="w-full border-t  px-3">
                        <div className="py-4">
                          <h1 className="text-[20px] poppin-600">Product</h1>
                        </div>
                        <TextBetween text="Name" value="Nike Shoes" />
                        <TextBetween text="Price" value="₦ 1000.00" />
                        <TextBetween text="Category" value="Shoes" />
                        <TextBetween text="Quantity" value="1" />
                      </div> */}
                    </InfoCard>

                    {/* address card  */}
                    <InfoCard header="Contact Detail" customClasses="mt-4">
                      <div className="px-3 py-2">
                        {inputs.map((inpt, index) => {
                          return (
                            <div
                              key={index}
                              className="flex justify-center items-center py-4"
                            >
                              <div className="min-w-[80px]">
                                <p className="poppin-500 text-[16px]">
                                  {inpt.label}
                                </p>
                              </div>
                              <div className="px-3">
                                <p className="poppin-500 text-[18px]">:</p>
                              </div>
                              <div className="w-full">
                                <input
                                  readOnly
                                  className="w-full border-b border-black outline-none px-2 cursor-pointer"
                                  type={inpt.type}
                                  placeholder={inpt.placeholder}
                                  name={inpt.name}
                                  value={
                                    selectedOrder[0]?.clientContactDetails[
                                      inpt.name
                                    ]
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </InfoCard>
                  </div>
                </div>
              </section>

              {/* screen size less than 764  */}
              <section className="w-full px-6 block md:hidden">
                {selectedOrder[0]?.cart.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full mb-2 py-2 px-3 bg-white rounded-lg flex gap-6 justify-center items-start"
                    >
                      {/* checkout product image  */}
                      <div className="w-[160px]  py-2 h-[130px] rounded-lg">
                        <img
                          className="w-full h-full rounded-lg"
                          src={item.productImage[0]}
                          alt="Product Banner"
                        />
                      </div>

                      {/* checkout product detail  */}
                      <div className="w-full flex justify-start items-start flex-col pl-4">
                        <h1 className="poppin-600 text-[20px] capitalize pt-3">
                          {item.productName}
                        </h1>
                        <h3 className="mt-2 poppin-500 text-[18px]">
                          Quantity : {item.orderQuantity}
                        </h3>
                        <div className="flex justify-between gap-8 items-center w-full mt-2">
                          <h6 className="text-[--blue] text-[18px]">
                            ₦ {item?.productPrice}
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <InfoCard header="Basic Information">
                  <div className="px-3 py-2">
                    <TextBetween
                      text="Order Date"
                      value={moment(selectedOrder[0]?.orderCreated).format(
                        "DD-MMM-YYYY"
                      )}
                    />
                    <TextBetween
                      text="Order No."
                      value={`#${selectedOrder[0]._id}`}
                    />
                    <TextBetween
                      text="Order Total"
                      value={`₦ ${selectedOrder[0].totalAmount}`}
                    />
                  </div>
                  {/* <div className="w-full border-t  px-3">
                    <div className="py-4">
                      <h1 className="text-[16px] sm:text-[18px] md:text-[20px] poppin-600">
                        Product
                      </h1>
                    </div>
                    <TextBetween text="Name" value="Nike Shoes" />
                    <TextBetween text="Price" value="₦ 1000.00" />
                    <TextBetween text="Category" value="Shoes" />
                    <TextBetween text="Quantity" value="1" />
                  </div> */}
                </InfoCard>

                <InfoCard customClasses="!mt-4" header="Price Summary">
                  <div className="px-3 py-2">
                    <TextBetween
                      text="Sub Total"
                      value={`₦ ${selectedOrder[0].orderSubTotal}`}
                    />
                    {/* <TextBetween text="Discount" value="₦ 0.00" />
                    <TextBetween text="Tax" value="₦ 0.00" /> */}
                    <TextBetween
                      text="Shipping Fee"
                      value={`₦ ${selectedOrder[0].orderFixedShippingFee || 0}`}
                    />
                    <div className="border-t  py-1">
                      <TextBetween
                        text="Total Amount"
                        value={`₦ ${selectedOrder[0].totalAmount}`}
                      />
                    </div>
                  </div>
                </InfoCard>

                <InfoCard header="Contact Detail" customClasses="mt-4">
                  <div className="px-3 py-2">
                    {inputs.map((inpt, index) => {
                      return (
                        <div
                          key={index}
                          className="flex justify-center items-center py-4"
                        >
                          <div className="min-w-[60px] sm:min-w-[80px]">
                            <p className="poppin-500 text-[12px] sm:text-[14px] md:text-[16px]">
                              {inpt.label}
                            </p>
                          </div>
                          <div className="px-3">
                            <p className="poppin-500 text-[12px] sm:text-[14px] md:text-[18px]">
                              :
                            </p>
                          </div>
                          <div className="w-full">
                            <input
                              readOnly
                              className="w-full cursor-pointer border-b border-black outline-none px-2 text-[12px] sm:text-[14px] md:text-[16px]"
                              type={inpt.type}
                              placeholder={inpt.placeholder}
                              name={inpt.name}
                              value={
                                selectedOrder[0]?.clientContactDetails[
                                  inpt.name
                                ]
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </InfoCard>
              </section>
            </>
          ) : (
            <div className="h-[30vh] w-screen flex justify-center items-center">
              <h2>order detail not found</h2>
            </div>
          )}
        </>
      )}
      <Link
        to="/orders"
        className={`w-[150px] rounded-full text-[--blue] border border-[--blue]  py-2 px-7 mt-5  mx-auto flex justify-between items-center transition-all ease-in-out hover:bg-[--blue] hover:text-white`}
      >
        <IoArrowBackOutline className="text-[26px]" />
        <p>Back</p>
      </Link>
      <Footer />
    </>
  );
};

export default OrderDetail;
