import React, { useEffect } from "react";
import NavMenu from "../Components/Navbar/NavMenu";
import SectionHeader from "../Components/Header/SectionHeader";
import Footer from "../Components/Footer/Footer";
import InfoCard from "../Components/Cards/InfoCard";
import TextBetween from "../Components/Wrapper/TextBetween";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStoreInfo } from "../Redux/actions";
import { isEmpty } from "lodash";
import Loader from "../Components/Loading/Loader";
let Once = true;

const About = () => {
  const dispatch = useDispatch();
  const storedValue = useSelector((state) => state.store);
  const { storeName } = useParams();
  const { loaderPrimary } = useSelector((state) => state.loader);
  const navigate = useNavigate();

  useEffect(() => {
    if (Once) {
      if (isEmpty(storedValue.data)) {
        dispatch(getStoreInfo({ storeName }))
          .then((res) => {
            console.log("Store about us");
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              navigate("/NotFound");
            } else {
              console.error("Store error ======>", err);
            }
          });
      }
    }
  }, [dispatch, navigate, storeName, storedValue]);

  if (loaderPrimary) {
    return <Loader />;
  }

  return (
    <>
      <NavMenu />
      <SectionHeader text="About" />
      <section className="pt-0 pb-6 flex flex-col md:flex-row justify-start items-center md:justify-between md:items-start px-3 sm:px-8 gap-6">
        <div className="w-full md:w-[50%]  lg:w-[40%]">
          <InfoCard customClasses="!mt-4" header="Basic Information">
            <div className="px-3 py-2">
              <div className="mb-2 mt-3">
                <h5 className="poppin-500 text-[14px] text-gray-400">
                  Company Name
                </h5>
                <h2 className="poppin-500 mt-1">
                  {storedValue.data[0]?.store?.storeCompanyName}
                </h2>
              </div>

              <div className="mb-2 mt-7">
                <h5 className="poppin-500 text-[14px] text-gray-400">
                  Store URL code
                </h5>
                <h2 className="poppin-500 sm:text-[14px] text-gray-400 mt-1 overflow-x-hidden text-ellipsis">
                  {process.env.REACT_APP_BASE_URL}
                  {storedValue?.data[0]?.store?.storeName}.com
                </h2>
              </div>

              <div className="mb-2 mt-7">
                <h5 className="poppin-500">Description</h5>
                <h2 className="poppin-500 text-[14px] text-gray-400 mt-1">
                  {storedValue?.data[0]?.store?.storeAbout}
                </h2>
              </div>
            </div>
          </InfoCard>
        </div>
        <div className="w-full md:w-[50%]  lg:w-[40%]">
          <InfoCard customClasses="!mt-4" header="Address Details">
            <div className="px-3 py-2">
              <TextBetween
                text="Street Name/No."
                value={storedValue?.data[0]?.store?.storeStreet}
              />
              <TextBetween
                text="City"
                value={storedValue?.data[0]?.store?.storeCity}
              />
              <TextBetween
                text="State"
                value={storedValue?.data[0]?.store?.storeState}
              />
              <TextBetween
                text="Zip Code"
                value={storedValue?.data[0]?.store?.storeZipCode}
              />
              <TextBetween
                text="Country"
                value={storedValue?.data[0]?.store?.storeCountry}
              />
            </div>
          </InfoCard>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
