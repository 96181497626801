import React from "react";

const CardWrapper = ({ children }) => {
  return (
    <div className="px-2 sm:px-4 md:px-8 py-4 flex justify-start items-start flex-wrap gap-6">
      {children}
    </div>
  );
};

export default CardWrapper;
