import React from "react";

const InfoCard = ({ header, customClasses, children }) => {
  return (
    <div className={`bg-white rounded-lg w-[full] ${customClasses}`}>
      <div className="w-full border-b py-5 px-3">
        <h1 className="text-[16px] sm:text-[18px] md:text-[20px] poppin-600">
          {header}
        </h1>
      </div>
      {children}
    </div>
  );
};

export default InfoCard;
