const initialState = {
  data: JSON.parse(localStorage.getItem("productData")) || [],
  currentPage: JSON.parse(localStorage.getItem("productCurrentPage")) || 1,
  totalPages: JSON.parse(localStorage.getItem("productTotalPages")) || 1,
  sort: JSON.parse(localStorage.getItem("productSort")) || -1,
};

const productReducer = (state = initialState, { type, payload, sort }) => {
  let newState;
  switch (type) {
    case "ALL_PRODUCT":
      newState = {
        ...state,
        data: payload.data,
        currentPage: payload.message.page,
        totalPages: payload.message.totalPages,
        sort: sort,
      };
      break;
    default:
      newState = state;
  }

  return newState;
};

export default productReducer;
