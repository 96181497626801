import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders } from "../Redux/actions";
import NavMenu from "../Components/Navbar/NavMenu";
import SectionHeader from "../Components/Header/SectionHeader";
import Loader from "../Components/Loading/Loader";
// import moment from "moment";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orders = useSelector((state) => state.orders.data);
  const loading = useSelector((state) => state.loader.loaderSecondary);
  useEffect(() => {
    if (orders.length === 0) {
      dispatch(fetchOrders());
    }
  }, [dispatch, orders.length]);
  return (
    <>
      <NavMenu />
      <SectionHeader text="Order History" />
      {loading ? (
        <Loader />
      ) : orders.length > 0 ? (
        <div className="mt-7 mb-3 dashboardTableWrapper w-[90%] mx-auto pb-8">
          <table className="bg-white w-full min-w-[850px] border-collapse !overflow-x-auto mt-3">
            <thead className="border-b-[15px] border-[--gray-light]">
              <tr>
                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                  Name
                </th>
                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                  Email
                </th>
                <th className="text-[--gray] font-[600] text-[16px] py-5 px-1 border">
                  Items Count
                </th>
                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                  Order Date
                </th>
                <th className="text-[--gray] font-[600] text-[16px] py-5 border px-2">
                  Amount
                </th>
                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                  Order Status
                </th>
                <th className="text-[--gray] font-[600] text-[16px] py-5 px-2 border">
                  Payment
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      navigate(`/order-detail/${order._id}`);
                    }}
                    className="cursor-pointer transition-all ease-in-out duration-500 hover:bg-blue-50"
                  >
                    <td className="border text-center px-2 text-sm py-2 capitalize">
                      {order.clientContactDetails.clientName}
                    </td>
                    <td
                      onClick={(e) => {
                        if (e.target.classList.contains("max-w-[160px]")) {
                          e.target.classList.remove("max-w-[160px]");
                          e.target.classList.add("max-w-max");
                        } else {
                          e.target.classList.remove("max-w-max");
                          e.target.classList.add("max-w-[160px]");
                        }
                      }}
                      className="cursor-pointer border text-center px-2 text-sm py-2 max-w-[160px] text-ellipsis overflow-hidden"
                    >
                      {order.clientContactDetails.clientEmail}
                    </td>
                    <td className="border text-center px-1 text-sm py-2 capitalize">
                      {order.cart.length}
                    </td>
                    <td className="border text-center px-2 text-sm py-2 capitalize">
                      {new Date(
                        Number(order.orderCreated)
                      ).toLocaleDateString()}
                    </td>
                    <td className="border text-center px-2 text-sm py-2 capitalize text-[--blue-light]">
                      ₦ {order.totalAmount}
                    </td>
                    <td className="text-center px-1 text-sm py-2 capitalize border">
                      <p
                        className={`px-3 py-2 border text-black ${
                          order.orderStatus.toLowerCase() === "completed"
                            ? " border-green-400 bg-green-100"
                            : order.orderStatus.toLowerCase() === "cancelled"
                            ? "border-red-200 bg-red-50"
                            : order.orderStatus.toLowerCase() === "shipped"
                            ? "border-blue-200 bg-blue-50"
                            : "border-yellow-300 bg-yellow-50"
                        }`}
                      >
                        {order.orderStatus}
                      </p>
                    </td>
                    <td className="px-2 text-center capitalize border">
                      <p
                        className={`py-2 ${
                          order.paid ? "text-green-500" : "text-red-500"
                        }`}
                      >
                        {order.paid ? "Paid" : "Not Paid"}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="h-[50vh] w-screen flex justify-center items-center">
          <h2>You don't have any order</h2>
        </div>
      )}
    </>
  );
};

export default Orders;
