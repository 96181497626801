import React from "react";

const NoInternet = () => {
  return (
    <div className="w-screen min-h-[300px] flex justify-center items-center flex-col">
      <h5 className="text-[35px] poppin-500 text-gray-500">
        Bad internet connection
      </h5>
      <button
        onClick={() => {
          window.location.reload();
        }}
        className="mt-3 border-[--blue] text-white bg-[--blue] rounded-md px-4 py-2"
      >
        Reload
      </button>
    </div>
  );
};

export default NoInternet;
