import React, { useContext, useEffect, useState } from "react";
import NavMenu from "../Components/Navbar/NavMenu";
import SectionHeader from "../Components/Header/SectionHeader";
import Footer from "../Components/Footer/Footer";
import { FaRegCopy } from "react-icons/fa6";
import Modal from "../Components/Modal/Modal";
import tick from "../Assets/Images/tick.png";
import ButtonBack from "../Components/Button/ButtonBack";
import { CiImageOn } from "react-icons/ci";
import { toast } from "react-toastify";
import {
  fetchOrders,
  fileUploader,
  getStoreInfo,
  placeOrder,
} from "../Redux/actions";
import Loader from "../Components/Loading/Loader";
import { CartContext } from "../Context/cartContext";
import { structureOrder } from "../Utils/cartFUnctions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
let Once = true;

const paymentInfoData = [
  {
    label: "Payment Type",
    name: "paymentType",
    value: "Bank Transfer",
    id: "paymentType",
    copy: false,
  },
  {
    label: "Bank Name",
    name: "bankName",
    id: "bankName",
    copy: false,
  },
  {
    label: "Account Number",
    name: "accountNumber",
    id: "bankNumber",
    copy: true,
  },
  {
    label: "Account Name",
    name: "accountName",
    id: "accountName",
    copy: true,
  },
];

const PaymentInfo = () => {
  const order = useSelector((state) => state.orders);
  const { setCart } = useContext(CartContext);
  const [modal, setModal] = useState(false);
  const [recieptForm, setReceiptForm] = useState(false);
  const [reciept, setReciept] = useState(null);
  const storeInfo = useSelector((state) => state.store);
  const storeName = localStorage.getItem("storeName");
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setError({ status: false, message: null });
      setReciept(file);
    }
  };

  function handleModal() {
    localStorage.setItem("cart", JSON.stringify([]));
    localStorage.setItem("formValues", null);
    localStorage.setItem("shippingFee", null);
    setCart([]);
    setModal(false);
    const storeName = localStorage.getItem("storeName");
    navigate(`/${storeName}`);
  }

  function handleOrderSubmit() {
    if (!reciept) {
      setError({ status: true, message: "Reciept is required" });
    } else {
      setLoading(true);
      fileUploader(reciept)
        .then((res) => {
          if (order.data.length === 0) {
            dispatch(fetchOrders());
          }
          dispatch(placeOrder(structureOrder(res.data[0]))).then((res) => {
            setModal(true);
            setLoading(false);
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }
  function handleClick(targetId) {
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      // Select the text inside the element
      const range = document.createRange();
      range.selectNode(targetElement);

      // Create a selection and select the text
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Copy the selected text to clipboard
      document.execCommand("copy");

      // Clean up and notify the user
      selection.removeAllRanges();
      toast.success("Copied successfully", {
        position: "bottom-center",
        autoClose: 300,
      });
    }
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(storeInfo.data)) {
        setLoading(true);
        dispatch(getStoreInfo({ storeName }))
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      }
    }
  }, [storeInfo, dispatch, storeName]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, []);

  return (
    <>
      {modal ? (
        <Modal>
          <div className="w-[92%] sm:w-[80%] md:w-[65%] lg:w-1/2 max-w-[500px] bg-white px-3 py-6 sm:px-6 rounded-lg flex justify-center items-center flex-col">
            <div className=" w-[120px] h-[120px] sm:w-[160px] sm:h-[160px] md:w-[200px] md:h-[200px]">
              <img className="w-full h-full" src={tick} alt="tick here" />
            </div>
            <p className="mt-8 text-[--blue] text-center poppin-600 text-[14px] sm:text-[16px]">
              Your order has been placed successfully
            </p>
            <button
              onClick={handleModal}
              className="mt-5 px-12 border border-[--blue] bg-[--blue] py-2 rounded-full text-white text-[18px] transition-all duration-300 ease-in-out hover:bg-white hover:text-[--blue]"
            >
              Done
            </button>
          </div>
        </Modal>
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <NavMenu />
          <SectionHeader text="Payment Info" />
          {recieptForm ? (
            <>
              <div className="w-full min-h-[25vh] flex justify-start items-center flex-col">
                <h1 className="poppin-500 text-[14px] sm:text-[16px] md:text-[18px] my-3">
                  Upload Payment Receip here
                </h1>
                <label
                  htmlFor="reciept"
                  className="cursor-pointer text-[--blue] flex justify-center items-center flex-col bg-white w-[50%] py-6"
                >
                  {reciept ? (
                    <img
                      src={URL.createObjectURL(reciept)}
                      className="w-[180px] h-[140px]"
                      alt="receipt"
                    />
                  ) : (
                    <CiImageOn className="text-[80px]" />
                  )}

                  <p className="text-[16px]">Upload Receipt</p>
                </label>
                {error.status ? (
                  <span className="text-[14px] text-red-500 mt-2">
                    {error.message}
                  </span>
                ) : null}
                <input
                  onChange={handleImage}
                  id="reciept"
                  type="file"
                  accept="image/*"
                  hidden
                />

                <div className="w-full flex justify-center items-center pt-10 pb-5">
                  <button
                    onClick={handleOrderSubmit}
                    className="px-8 sm:px-16 md:px-24 border border-[--blue] bg-[--blue] py-2 rounded-full text-white text-[18px] transition-all duration-300 ease-in-out hover:bg-white hover:text-[--blue]"
                  >
                    I have sent the money
                  </button>
                </div>
              </div>
              <button
                onClick={() => {
                  setReceiptForm(false);
                }}
                className="flex m-auto"
              >
                <ButtonBack customclasses="!mt-0" />
              </button>
            </>
          ) : (
            <>
              <section className="w-[98%] sm:w-[80%] md:w-[70%] lg:w-[60%] mx-auto px-4">
                {paymentInfoData.map((item, index) => {
                  return (
                    <div key={index} className="w-full px-1 sm:px-3 mt-5">
                      <p className="text-[14px] sm:text-[16px]">{item.label}</p>
                      <div
                        onClick={() => {
                          item.copy
                            ? handleClick(item.id)
                            : console.log("No copied allowed");
                        }}
                        className="w-full text-[14px] sm:text-[16px] bg-white rounded-xl py-3 px-4 mt-1 flex justify-between items-center cursor-pointer"
                      >
                        {item.name === "paymentType" ? (
                          <h1 id={item.id}>{item.value}</h1>
                        ) : (
                          <h1 id={item.id}>
                            {storeInfo?.data[0]?.bankDetail[item.name]}
                          </h1>
                        )}
                        {item.copy ? (
                          <FaRegCopy className="text-[22px] sm:text-[26px] md:text-[32px] text-[--blue] " />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
              </section>
              <div className="w-full flex justify-center items-center pt-10 pb-5">
                <button
                  onClick={() => {
                    setReceiptForm(true);
                    window.scrollTo(0, 0); // Scroll to top when component mounts
                  }}
                  className="px-5 sm:px-10 border border-[--blue] bg-[--blue] py-2 rounded-full text-white text-[12px] sm:text-[18px] transition-all duration-300 ease-in-out hover:bg-white hover:text-[--blue]"
                >
                  Make Transfer & Continue
                </button>
              </div>
              <ButtonBack
                onClick={() => {}}
                url="/checkout"
                customclasses="!mt-0"
              />
            </>
          )}

          <Footer />
        </>
      )}
    </>
  );
};

export default PaymentInfo;
