const initialState = {
  loaderPrimary: false,
  loaderSecondary: false,
  mainLoader: false,
};

const loaderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "PRIMARY_LOAD":
      return {
        ...state,
        loaderPrimary: true,
      };
    case "SECONDARY_LOAD":
      return {
        ...state,
        loaderSecondary: true,
      };
    case "LOAD_APP":
      return {
        ...state,
        mainLoader: true,
      };
    case "UNLOAD":
      return {
        ...state,
        loaderPrimary: false,
        loaderSecondary: false,
        mainLoader: false,
      };
    default:
      return state;
  }
};

export default loaderReducer;
