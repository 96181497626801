import { RouterProvider } from "react-router-dom";
import Routes from "./Routes";
import { useEffect, useState } from "react";
import { CartContext } from "./Context/cartContext";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "./Redux/actions";
import Loader from "./Components/Loading/Loader";
let Once = true;
function App() {
  const [cart, setCart] = useState([]);
  const dispatch = useDispatch();
  const { mainLoader } = useSelector((state) => state.loader);

  useEffect(() => {
    const cartItems = localStorage.getItem("cart");
    setCart(JSON.parse(cartItems) || []);
    if (Once) {
      dispatch(refreshToken());
    }
  }, [dispatch]);
  return (
    <>
      {mainLoader ? (
        <section className="flex justify-center items-center w-screen h-screen">
          <Loader />
        </section>
      ) : (
        <CartContext.Provider value={{ cart, setCart }}>
          <RouterProvider router={Routes} />
        </CartContext.Provider>
      )}
    </>
  );
}

export default App; 
