import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AuthForm from "../../Components/Form/AuthForm";
import { CiUser, CiLock, CiMail } from "react-icons/ci";
import InputPassword from "../../Components/Input/InputPassword";
import InputWrapper from "../../Components/Wrapper/InputWrapper";
import Input from "../../Components/Input/Input";
import { Link } from "react-router-dom";
import { getStoreInfo, signUp } from "../../Redux/actions";
import { useFormik } from "formik";
import { registerSchema } from "../../Schema";
import Loader from "../../Components/Loading/Loader";
import ButtonLoading from "../../Components/Button/ButtonLoading";
import { isEmpty } from "lodash";
import { errorToast } from "../../Utils/Toast";
let Once = true;

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storeInfo = useSelector((state) => state.store);
  const { loaderPrimary, loaderSecondary } = useSelector(
    (state) => state.loader
  );
  // Move the useState hook to the top level
  const { storeName } = useParams();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: registerSchema,
      onSubmit: (values, action) => {
        dispatch(
          signUp({
            ...values,
            venderId: storeInfo.data[0].store.venderId.toString(),
          })
        )
          .then((res) => {
            action.resetForm();
            navigate("/login");
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              errorToast(err.response.data.message);
            } else {
              errorToast("Something went wrong");
            }
          });
      },
    });

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(storeInfo.data)) {
        dispatch(getStoreInfo({ storeName }))
          .then((result) => {
            console.log("Store found");
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              navigate("/NotFound");
            } else {
              console.error(err);
            }
          });
      }
    }
  }, [dispatch, navigate, storeInfo, storeName]);

  return loaderPrimary ? (
    <Loader />
  ) : (
    <AuthForm
      header="Sign Up"
      description="Enter the required information to continue."
      storeInfo={storeInfo.data[0]}
    >
      <div className="w-full mx-auto pt-6 pb-2 px-4">
        <InputWrapper customclasses={"!items-stretch"}>
          <Input
            name="firstName"
            label="First Name"
            type="text"
            placeholder="Enter your first name"
            icon={<CiUser />}
            onChange={handleChange}
            value={values.firstName}
            error={errors.firstName}
            touch={touched.firstName}
            onBlur={handleBlur}
          />
          <Input
            name="lastName"
            label="Last Name"
            type="text"
            placeholder="Enter your last name"
            icon={<CiUser />}
            onChange={handleChange}
            value={values.lastName}
            error={errors.lastName}
            touch={touched.lastName}
            onBlur={handleBlur}
          />
        </InputWrapper>
        <InputWrapper customclasses="mt-6 !items-stretch">
          <Input
            name="email"
            label="Email"
            type="email"
            placeholder="Enter your email address"
            icon={<CiMail />}
            onChange={handleChange}
            value={values.email}
            error={errors.email}
            touch={touched.email}
            onBlur={handleBlur}
          />
          <InputPassword
            name="password"
            label="Password"
            type="password"
            placeholder="Enter your password"
            icon={<CiLock />}
            onChange={handleChange}
            value={values.password}
            error={errors.password}
            touch={touched.password}
            onBlur={handleBlur}
          />
        </InputWrapper>
      </div>

      <div className="w-full text-center mt-3">
        {loaderSecondary ? (
          <ButtonLoading customClasses={"!w-1/2 !rounded-full !mx-auto"} />
        ) : (
          <button
            onClick={handleSubmit}
            className="px-6 w-1/2 py-3 text-white text-[16px] rounded-full bg-[--blue] poppin-500"
          >
            Sign Up
          </button>
        )}
      </div>
      <div className="w-full md:w-1/2 mt-2 mx-auto text-center py-1 ">
        <span className="poppin-500 text-[14px]">
          By creating an account, you agree to our{" "}
          <Link className="text-[--blue] poppin-500" to="/login">
            Terms & conditions
          </Link>{" "}
          and{" "}
          <Link className="text-[--blue] poppin-500" to="/login">
            Privacy Policy.
          </Link>
        </span>
      </div>
      <div className="w-full text-center pb-3">
        <span className="poppin-500 text-[14px]">
          Already have an account?{" "}
          <Link className="text-[--blue] poppin-500" to="/login">
            Sign In
          </Link>
        </span>
      </div>
    </AuthForm>
  );
};

export default Register;
