import React from "react";

const AuthForm = ({ header, description, children, storeInfo }) => {
  return (
    <div className="w-screen min-h-screen flex justify-start items-center flex-col pt-4 pb-8 px-0 sm:px-4">
      <div className="w-full py-4 flex justify-center items-center flex-col">
        <div className="w-[90px] h-[90px] sm:w-[110px] sm:h-[110px] md:w-[130px] md:h-[130px]">
          <img
            className="w-full h-full rounded-full"
            src={storeInfo.store.venderProfileImage}
            alt=""
          />
        </div>
        <h1 className="mt-2 poppin-600 text-[20px] sm:text-[25px] md:text-[30px]">
          {storeInfo.store.storeName}
        </h1>
      </div>
      <div className="w-full sm:w-[90%] md:w-[70%] bg-white rounded-3xl sm:rounded-lg p-4 px-8 ">
        <div className="w-full">
          <div className="w-full py-2">
            <h1 className="text-[--blue] poppin-700 text-[22px] sm:text-[26px] md:text-[30px]">
              {header}
            </h1>
          </div>
          <div>
            <p className="poppin-500 text-[14px] sm:text-[16px]">
              {description}
            </p>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
