import axios from "axios";
import { errorToast } from "../../Utils/Toast";
import authReducer from "../reducers/authReducer";
function apiCall() {
  const accessTokenFetched = localStorage.getItem("accessToken");
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    // baseURL: Process.env.REACT_APP_BASEURL,
    withCredentials: true,
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessTokenFetched}`,
    },
    timeout: 60000, // 60 seconds timeout
  });
  return api;
}
// http://localhost:3000/newcompanytestokok

export const getStoreInfo = (payload) => async (dispatch) => {
  try {
    const api = apiCall();
    dispatch({ type: "PRIMARY_LOAD" });
    const storeName = localStorage.getItem("storeName");
    const response = await api.get(`/store/?storeName=${storeName}`);
    dispatch({ type: "STORE_INFO", payload: response.data });
    dispatch({ type: "UNLOAD" });
    await dispatch(getProfile());
    return response;
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    return error.response;
  }
};

export const products =
  ({ storeName, page = 1, limit = 15, sort = -1 }) =>
  async (dispatch) => {
    try {
      const api = apiCall();
      dispatch({ type: "SECONDARY_LOAD" });
      const response = await api.get(
        `/product/forPublic?storeName=${storeName}&page=${page}&limit=${limit}&sort=${sort}`
      );
      dispatch({ type: "ALL_PRODUCT", payload: response.data, sort });
      dispatch({ type: "UNLOAD" });

      return;
    } catch (error) {
      console.log(error);
      dispatch({ type: "UNLOAD" });
    }
  };

export const fetchProductDetail = (payload) => async (dispatch) => {
  try {
    const api = apiCall();
    dispatch({ type: "SECONDARY_LOAD" });
    const response = await api.get(
      `/product/singleProduct?productId=${payload}`
    );
    dispatch({ type: "UNLOAD" });
    return response;
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    throw error;
  }
};

export const refreshToken = () => async (dispatch) => {
  try {
    const api = apiCall();
    dispatch({ type: "LOAD_APP" });
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await api.get(`/buyer/refreshToken`, {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${refreshToken}`,
      },
      withCredentials: true,
    });
    dispatch({ type: "LOGIN", payload: response.data });
    dispatch({ type: "UNLOAD" });
    return;
  } catch (error) {
    console.log(error);
    dispatch({ type: "UNLOAD" });
  }
};

export const signUp = (payload) => async (dispatch) => {
  try {
    const api = apiCall();
    dispatch({ type: "SECONDARY_LOAD" });
    const response = await api.post(`/buyer/signup`, payload);
    dispatch({ type: "UNLOAD" });
    return response;
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    throw error;
  }
};

export const login = (payload) => async (dispatch) => {
  try {
    const api = apiCall();
    dispatch({ type: "SECONDARY_LOAD" });
    const response = await api.post(`/buyer/login`, payload);
    dispatch({ type: "LOGIN", payload: response.data });
    dispatch({ type: "UNLOAD" });
    localStorage.setItem("accessToken", response.data.data.accessToken);
    localStorage.setItem("refreshToken", response.data.data.refreshToken);
    return response;
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    throw error;
  }
};
export const getProfile = (payload) => async (dispatch) => {
  try {
    const api = apiCall();
    dispatch({ type: "SECONDARY_LOAD" });
    const response = await api.get(`/buyer/getProfile`);
    dispatch({ type: "LOGIN", payload: response.data });
    dispatch({ type: "UNLOAD" });
    return response;
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    throw error;
  }
};

export const logout = () => async (dispatch) => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const api = axios.create({
      baseURL: "http://localhost:8000/api/v1/",
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${refreshToken}`,
      },
      timeout: 60000, // 60 seconds timeout
    });
    const response = await api.post(`/buyer/logout`);
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("refreshToken");
    dispatch({ type: "LOGOUT" });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fileUploader = async (payload) => {
  try {
    const api = apiCall();
    const formData = new FormData();
    formData.append("file", payload);
    const response = await api.post("/aws/uploadImgBuyer", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchOrders = (payload) => async (dispatch) => {
  try {
    const api = apiCall();
    dispatch({ type: "SECONDARY_LOAD" });
    const response = await api.get("order/orderHistory");
    dispatch({ type: "ALL_ORDER", payload: response.data });
    dispatch({ type: "UNLOAD" });
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch({ type: "UNLOAD" });
    } else {
      errorToast("Something went wrong");
    }
  }
};

export const placeOrder = (payload) => async (dispatch) => {
  try {
    const api = apiCall();
    const response = await api.post("/order", payload);
    dispatch({ type: "INSERT_ORDER", payload: response.data });
    return response;
  } catch (error) {
    errorToast("Something went wrong");
    throw error;
  }
};

export const sendOtp = (payload) => async (dispatch) => {
  try {
    const api = apiCall();
    const response = await api.get(
      `/buyer/forgetPassword/?email=${payload.email}`
    );
    // console.log("sent Otp success==> ", response);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const validateOtp = (payload) => async (dispatch) => {
  try {
    const api = apiCall();
    const response = await api.get(
      `/vender/otpValidation?otp=${payload.otp}&encryptOpts=${payload.encryptOpts}`
    );
    // console.log("validate otp success==> ", response);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const setPassword = (payload) => async (dispatch) => {
  try {
    const api = apiCall();
    const response = await api.post(`/buyer/setPassword`, {
      email: payload.email,
      encryptOpts: payload.encryptOpts,
      otp: payload.otp,
      newPassword: payload.newPassword,
    });
    // console.log("set password success==> ", response.data);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response.data;
  }
};
export const searchProducts = (payload) => async (dispatch) => {
  try {
    const api = apiCall();
    const response = await api.get(
      `/product/searchProductForSpecificStore?venderId=${payload.venderId}&searchTerm=${payload.searchTerm}`
    );
    // console.log("set password success==> ", response.data);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response.data;
  }
};

const api = apiCall();

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
      // Handle timeout error here, for example, navigate to "/time-out" page
      window.location.href = `/timeout-page/${encodeURIComponent(
        window.location.pathname
      )}`;
    } else {
      return Promise.reject(error);
    }
    if (
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const accessTokenFetched = localStorage.getItem("accessToken");
        const response = await axios.get(
          `${"https://dev.nesmaspoint.com/api/v1"}/buyer/refreshToken`,
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${accessTokenFetched}`,
            },
            withCredentials: true,
          }
        );
        authReducer({ type: "LOGIN", payload: response.data });
        return api.request(originalRequest);
      } catch (error) {
        authReducer({ type: "LOGOUT" });
      }
    }
    throw error;
  }
);
