import * as Yup from "yup";

const stringValidation = () => Yup.string().required("Field is required");

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} `;
};

export const orderSchema = Yup.object({
  clientName: Yup.string().required("Client name is required"),
  clientEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  clientPhoneNumber: Yup.string()
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Phone number can be at most 15 digits")
    .required("Phone number is required"),
  clientAddress: Yup.string().required("Address is required"),
  clientNote: Yup.string().required("note is required"),
});

export const registerSchema = Yup.object({
  firstName: stringValidation("First Name is required"),
  lastName: stringValidation("Last Name is required"),
  email: stringValidation("Email is required").email(),
  password: stringValidation("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase character"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase character"))
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      getCharacterValidationError("special character")
    ),
});

export const loginSchema = Yup.object({
  email: stringValidation("Email is required").email(),
  password: stringValidation("Password is required"),
});
