export function returnProductQuantity(productId, productList) {
  const isExist = productList.find((product) => product._id === productId);
  if (isExist) {
    return isExist.quantity;
  } else {
    return "1";
  }
}

function findProduct(cart, productId) {
  return cart.find((product) => product._id === productId);
}

function checkQuantity(product, quantity) {
  return quantity <= product.productQuantity;
}

function cartQuantityCheck(cart, quantity, product, detail = false) {
  const productDetail = findProduct(cart, product._id);
  if (detail) {
    return productDetail.productQuantity >= quantity;
  } else {
    return productDetail.productQuantity >= productDetail.quantity + quantity;
  }
}

export async function addToCart(
  cart,
  product,
  isProductDetail = false,
  quantity = 1
) {
  let _cart = [...cart];
  if (!_cart.length) {
    _cart = [
      {
        ...product,
        quantity: quantity,
      },
    ];
  } else {
    // find if product exist or not
    const isExist = findProduct(_cart, product._id);
    // if its not in cart
    if (!isExist) {
      // check if quantity is greater than 1
      if (quantity > 1) {
        //check if user entered quantity is minimum than available quantity
        if (checkQuantity(product, quantity)) {
          _cart = [..._cart, { ...product, quantity: quantity }];
        } else {
          throw new Error("Out of stock");
        }
      } else {
        _cart = [..._cart, { ...product, quantity: quantity }];
      }
    } else {
      // check user quantity
      if (cartQuantityCheck(_cart, quantity, product, isProductDetail)) {
        let updatedCart = _cart.map((cartItem) =>
          cartItem._id === product._id
            ? {
                ...cartItem,
                quantity: isProductDetail
                  ? quantity
                  : cartItem.quantity + quantity,
              }
            : cartItem
        );
        _cart = updatedCart;
      } else {
        throw new Error("Out of stock");
      }
    }
  }
  return _cart;
}

export function calculateSubtotal() {
  let cart = JSON.parse(localStorage.getItem("cart"));
  let total = 0;
  if(!cart) return total=0;
  cart.forEach((item) => {
    total += item.quantity * item.productSellingPrice;
  });

  return total;
}

export function calculateTotal(shippingfee = 0) {
  let subtotal = calculateSubtotal();
  return subtotal + shippingfee;
}

export function structureOrder(imageUrl) {
  const clientInfo = JSON.parse(localStorage.getItem("formValues"));
  const _cart = JSON.parse(localStorage.getItem("cart"));
  const cartObject = _cart.map((product) => {
    return {
      productId: product._id,
      orderQuantity: product.quantity,
    };
  });
  return {
    cart: cartObject,
    orderFixedShippingFee: JSON.parse(localStorage.getItem("shippingFee")),
    clientContactDetails: {
      clientName: clientInfo.clientName,
      clientEmail: clientInfo.clientEmail,
      clientPhoneNumber: clientInfo.clientPhoneNumber,
      clientAddress: clientInfo.clientAddress,
    },
    clientNote: clientInfo.clientNote,
    paymentReceiptImg: imageUrl,
  };
}

export function handleAddQuantity(product) {
  let cart = JSON.parse(localStorage.getItem("cart"));
  if (product.quantity < product.productQuantity) {
    let newCart = cart.map((item) => {
      if (item._id === product._id) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      } else {
        return item;
      }
    });
    localStorage.setItem("cart", JSON.stringify(newCart));
    return newCart;
  } else {
    return cart;
  }
}

export function handleDecreaseQuantity(product) {
  let cart = JSON.parse(localStorage.getItem("cart"));
  if (product.quantity > 1) {
    let newCart = cart.map((item) => {
      if (item._id === product._id) {
        return {
          ...item,
          quantity: item.quantity - 1,
        };
      } else {
        return item;
      }
    });
    localStorage.setItem("cart", JSON.stringify(newCart));
    return newCart;
  } else {
    return cart;
  }
}
