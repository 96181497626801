import React from "react";
import emptyCart from "../Assets/Images/emptyCart.png";
import { Link } from "react-router-dom";

const EmptyCart = () => {
  const storeName = localStorage.getItem("storeName");
  return (
    <div className="w-screen h-[250px] flex justify-center items-center">
      <div className="py-4 flex justify-center items-center">
        <img
          src={emptyCart}
          alt="Empty Cart "
          className="w-[230px] h-[150px]"
        />
      </div>
      <div className="flex justify-center items-start flex-col px-8">
        <h2 className="text-[46px] poppin-700 ">OOPS!</h2>
        <h6 className="poppin-500 text-[20px] max-w-[500px]">
          Your cart is empty. Please add some products to cart and come again
        </h6>
        <Link
          className="mt-3 border-[--blue] text-white bg-[--blue] rounded-md px-4 py-2"
          to={`/${storeName}`}
        >
          Go Back
        </Link>
      </div>
    </div>
  );
};

export default EmptyCart;
