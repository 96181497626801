import { combineReducers } from "redux";
import authReducer from "./authReducer";
import productReducer from "./productReducer";
import storeReducer from "./storeReducer";
import loaderReducer from "./loaderReducer";
import orderReducer from "./orderReducer";
export default combineReducers({
  auth: authReducer,
  product: productReducer,
  store: storeReducer,
  loader: loaderReducer,
  orders: orderReducer,
});
